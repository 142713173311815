import React from "react";
import image1 from "../../assets/specialist.png"
import image2 from "../../assets/fda.png"
import image3 from "../../assets/consult.png"
import image4 from "../../assets/therapists.png"
import image5 from "../../assets/happy.png"
import image6 from "../../assets/guide.png"
import { FaStar } from "react-icons/fa";
const Whyus = () => {
  const whyUsData = [
    {
      id: 1,
      title: "Skin Specialists",
      description: "Nepal's most trusted and professional team of Skin Specialists",
      color: "bg-pink-100",
      image: image1
    },
    {
      id: 2,
      title: "Advance Technology",
      description: "All technologies used at Presu are USA FDA Approved",
      color: "bg-rose-100",
      image: image2

    },
    {
      id: 3,
      title: "Best Consultation",
      description: "One on one in clinic consultation along with online consultation facility",
      color: "bg-purple-100",
      image: image3

    },
    {
      id: 4,
      title: "Certified Skin Therapist",
      description: "Certified and amiable team of well trained skin therapists",
      color: "bg-red-100",
      image: image4

    },
    {
      id: 5,
      title: "Customer Satisfaction",
      description: "Only clinic in Chitwan with 100% client satisfaction",
      color: "bg-teal-100",
      image: image5

    },
    {
      id: 6,
      title: "AAD Guidelines",
      description: "All treatment protocols are as per the AAD guidelines",
      color: "bg-indigo-100",
      image: image6

    },
  ];

  return (
    <div className="px-3 md:lg:xl:px-4 py-10 bg-opacity-10">
      <div className="flex flex-col items-center justify-center mb-2">
        <div className="flex justify-center font-bold font-dancing text-4xl text-[#DD0786] px-4">
          Why Choose Us?
        </div>
        <div className="flex justify-center items-center w-28">
          <div className="flex-grow bg-gradient-to-r from-purple-500 to-pink-500 rounded-full w-8 h-1"></div>
          <FaStar className="mx-2 text-pink-500" />
          <div className="flex-grow bg-gradient-to-r from-pink-500 to-purple-500 rounded-full w-8 h-1"></div>
        </div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 group bg-white shadow-xl shadow-neutral-100 border">
        {whyUsData.map((item) => (
          <div key={item.id} className="p-10 flex flex-col items-center text-center group md:lg:xl:border-r md:lg:xl:border-b hover:bg-slate-50 cursor-pointer">
            <span className={`p-[1px] flex rounded-full ${item.color} text-white shadow-lg shadow-${item.color.slice(3)}-200`}>
              <img className="w-24 h-24  rounded-full object-cover" src={item.image} alt="Icon" />
            </span>
            <p className="text-xl font-medium text-slate-700 font-sriracha mt-3">{item.title}</p>
            <p className="mt-2 text-sm text-slate-500">{item.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Whyus;
