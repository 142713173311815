import React from "react";
import logo from "../assets/logo.png";
import { FaFacebook, FaInstagram, FaTiktok, FaYoutube } from "react-icons/fa";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <>
      <div className="w-full bg-pink-500 h-[2px] mb-[2px]"></div>
      <div className="w-full bg-pink-500 h-[2px]"></div>

      <footer className="bg-slate-50 py-8 px-4">
        <div className="container mx-auto flex sm:flex-row  flex-col justify-between gap-2">
          <div className="w-full md:w-1/2 lg:w-1/4 mb-4 md:mb-0 ">
            <img src={logo} alt="" className="mb-4 w-full max-w-[200px]" />
            <p className="text-gray-600">
              Presu Skin Care is a premium skincare brand dedicated to providing
              you with high-quality, natural products that nourish and
              rejuvenate your skin.
            </p>{" "}
          </div>

          <div className="w-full md:w-1/2 lg:w-1/4 mb-4 md:mb-0 ">
            <h3 className="text-gray-700 font-bold mb-3 ">Quick Links</h3>
            <ul className="list-none footer-links text-gray-600">
              <li className="mb-2">
                <Link
                  to="/about"
                  className="border-b border-solid border-transparent hover:border-pink-500 hover:text-pink-500"
                >
                 ➣ About Us
                </Link>
              </li>
              <li className="mb-2">
                <Link
                  to="/services"
                  className="border-b border-solid border-transparent hover:border-pink-500 hover:text-pink-500"
                >
                 ➣ Services
                </Link>
              </li>
              <li className="mb-2">
                <Link
                  to="/product"
                  className="border-b border-solid border-transparent hover:border-pink-500 hover:text-pink-500"
                >
                 ➣ Shop
                </Link>
              </li>
              <li className="mb-2">
                <Link
                  to="/blog"
                  className="border-b border-solid border-transparent hover:border-pink-500 hover:text-pink-500"
                >
                 ➣ Blog
                </Link>
              </li>
              <li className="mb-2">
                <Link
                  to="/gallery"
                  className="border-b border-solid border-transparent hover:border-pink-500 hover:text-pink-500"
                >
                 ➣ Gallery
                </Link>
              </li>
            </ul>
          </div>
          <div className="w-full md:w-1/2 lg:w-1/4 mb-4 md:mb-0 ">
            <h3 className="text-gray-700 font-bold mb-3">More Info</h3>
            <ul className="list-none footer-links text-gray-600">
              <li className="mb-2">
                <Link
                  to="/"
                  className="border-b border-solid border-transparent hover:border-pink-500 hover:text-pink-500"
                >
                 ↠ Our Team
                </Link>
              </li>
              <li className="mb-2">
                <Link
                  to="/"
                  className="border-b border-solid border-transparent hover:border-pink-500 hover:text-pink-500"
                >
                 ↠ Privacy Policy
                </Link>
              </li>
              <li className="mb-2">
                <Link
                  to="/"
                  className="border-b border-solid border-transparent hover:border-pink-500 hover:text-pink-500"
                >
                 ↠ Terms & Condition
                </Link>
              </li>
              <li className="mb-2">
                <Link
                  to="/"
                  className="border-b border-solid border-transparent hover:border-pink-500 hover:text-pink-500"
                >
                 ↠ Location
                </Link>
              </li>
            </ul>
          </div>
          <div className="w-full md:w-1/2 lg:w-1/4">
            <h3 className="text-gray-700 font-bold mb-3">Find us on Map</h3>
            <div className="h-40">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3532.761121606414!2d84.41747747361667!3d27.693777051280623!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3994fb6ad0137dfb%3A0x3717ec1ac5f4cab0!2sPresu%E2%80%99s%20Skincare%20(Best%20Skincare%20Center%20in%20Chitwan)!5e0!3m2!1sen!2snp!4v1695794717219!5m2!1sen!2snp"
                width="100%"
                height="100%"
                style={{
                  border: "none",
                }}
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </div>
        </div>
        <div className="flex justify-center mt-6 pt-6 border-t">
          <p className="text-gray-600 text-sm">
            &copy; 2023 Presu's Skincare | Designed by{" "}
            <a
              className="text-pink-600 font-semibold"
              target="_blank"
              rel="noopener noreferrer"
              href="https://acetechnepal.com"
            >
              AceTech
            </a>
          </p>
        </div>
      </footer>
    </>
  );
};

export default Footer;
