import React from "react";
import ReactStars from "react-rating-stars-component";
import { BASE_URL } from "../../context/AuthContext";

const SatisfiedCard = (props) => {
  return (
    <div className="bg-white rounded-lg shadow-lg py-6 px-2 w-72">
      {/* <div className="flex items-center justify-center mb-4">
        <img
          src={`${BASE_URL}${props.img}`}
          alt={props.name}
          className="rounded-full w-20 h-20"
        />
      </div> */}
      <h1 className="text-xl font-semibold text-center mb-2">{props.name}</h1>
      <div
        className="text-gray-600 text-center mb-2"
        dangerouslySetInnerHTML={{ __html: props.desc1 }}
      ></div>
      <div className="flex justify-center">
        <ReactStars
          count={props.count}
          size={24}
          value={props.value}
          edit={false}
          activeColor="#DD0786"
        />
      </div>
    </div>
  );
};

export default SatisfiedCard;
