import React, { useEffect, useState } from "react";
import axios from "axios";
import image from "../../assets/Thatmatt.png";
import { useParams } from "react-router-dom";
import Shopping from "../component/Shopping";
import url from "../../utils/useAxios";
import { BASE_URL } from "../../context/AuthContext";
import { Helmet } from "react-helmet";
import { FaArrowRight } from "react-icons/fa";
const api = url();

const ServiceDetail = () => {
  const [serviceItems, setServiceItems] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);

  const params = useParams();

  const getData = async (page) => {
    const res = await api.get(`/service/?page=${page}`);
    setServiceItems(res.data.service.filter((item) => item.id == params.id));
    console.log(res.data.service);
  };

  useEffect(() => {
    getData(currentPage);
    console.log("servicessss");
  }, [currentPage]);

  if (serviceItems.length === 0) {
    return <div>Loading...</div>; // Render a loading state while fetching data
  }

  return (
    <>
      <Helmet>
        <title>{serviceItems[0]?.name} | Presu's Skincare</title>
        <meta name="description" content={serviceItems[0]?.description} />
      </Helmet>
      <div className="container mx-auto mt-36">
        <div className="flex flex-col md:flex-row justify-center items-stretch gap-4 mt-8">
          <div className=" rounded-lg p-8 md:w-1/2 flex flex-col">
            <div className="flex items-center justify-between">
              <h1 className="text-4xl text-pink-600 font-semibold font-sriracha  mb-4 ">
                {serviceItems[0]?.name}
              </h1>
              <p className="text-xl text-gray-700">
                Price: Rs. {serviceItems[0]?.cost}
              </p>
            </div>
            <p className="text-lg text-gray-700 text-justify mb-4" dangerouslySetInnerHTML={{ __html: serviceItems[0]?.description }}>
              {/* {serviceItems[0]?.description} */}
            </p>
          </div>
          <div className="md:w-1/2 flex items-center">
            <img
              src={`${BASE_URL}${serviceItems[0]?.image}`}
              className="w-full h-auto rounded-md"
              alt=""
            />
          </div>
        </div>
        <div className="rounded-lg p-8 mt-8">
          <h1 className="text-3xl text-pink-600 font-semibold font-sriracha mb-4">
            Benefits
          </h1>
          <ul className="text-lg text-gray-700 pl-6">
          <p className="text-lg text-gray-700 text-justify mb-4" dangerouslySetInnerHTML={{ __html: serviceItems[0]?.benefits }}></p>

          </ul>
        </div>
        <Shopping />
      </div>
    </>
  );
};

export default ServiceDetail;
