import React, { useState, useEffect } from "react";
import Satisfiedcard from "../childcomponent/Satisfiedcard";
import axios from "axios";
import backgroundImage from "../../assets/3.png";
import url from "../../utils/useAxios";
import { FaStar } from "react-icons/fa";
const api = url();

const Satisfied = () => {
  const bgImg = `url(${backgroundImage})`;
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1); 

  const getData = async (page) => {
    const res = await api.get(`/testimonials?page=${page}`);
    setData(res.data);
    console.log(res.data);
  };

  useEffect(() => {
    getData(currentPage);
    console.log("satisfied");
  }, [currentPage]);

  return (
    <>
      <div className="py-10" style={{ backgroundImage: bgImg }}>
      <div className="flex flex-col items-center justify-center mb-2">
        <div className="flex justify-center font-bold font-dancing text-4xl text-[#DD0786] px-4">
          Words From Our Customers
        </div>
        <div className="flex justify-center items-center w-28">
          <div className="flex-grow bg-gradient-to-r from-purple-500 to-pink-500 rounded-full w-8 h-1"></div>
          <FaStar className="mx-2 text-pink-500" />
          <div className="flex-grow bg-gradient-to-r from-pink-500 to-purple-500 rounded-full w-8 h-1"></div>
        </div>
      </div>
        <div className="flex flex-wrap justify-center gap-4 p-4">
          {data?.slice(0, 4).map((items) => (
            <Satisfiedcard
              key={items.id}
              name={items.name}
              desc1={items.message}
              count={5}
              value={items.rating}
            />
          ))}
        </div>
      </div>
    </>
  );
};

export default Satisfied;
