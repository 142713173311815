import React, { useState, useEffect } from "react";
import axios from "axios";
import Shopping from "../component/Shopping";
import backgroundImage from "../../assets/5.png";
import url from "../../utils/useAxios";
import { ToastContainer, toast } from "react-toastify";
import { Helmet } from "react-helmet";
const api = url();

const Contact = () => {
  const bgImg = `url(${backgroundImage})`;
  const [serviceItems, setServiceItems] = useState([]);
  const [selectedService, setSelectedService] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [phone, setPhone] = useState("");

  const getData = async () => {
    try {
      const res = await api.get("/service/?page=1");
      setServiceItems(res.data.service);
      console.log(res.data.service);
    } catch (error) {
      console.error("Error fetching service data:", error);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  //  post data for contact form

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("name", name);
    formData.append("email", email);
    formData.append("service", selectedService);
    formData.append("message", message);
    formData.append("phone_number", phone);

    try {
      await api.post("/contact", formData).then(() => {
        console.log("success");
        setSubmitted(true);
        toast.success("Message Send Successfully");
      });
    } catch (error) {
      console.error("Error posting data:", error);
    }
  };

  return (
    <>
      <Helmet>
        <title>Contact Us | Presu's Skincare</title>
        <meta
          name="description"
          content="Get in touch with Presu's Skincare for all your skincare queries and appointments. We are located in Saheed Chowk, Chitwan."
        />
        <meta
          name="keywords"
          content="Contact Presu's Skincare, skincare queries, appointments, Saheed Chowk, Chitwan"
        />
      </Helmet>
      <div className="py-12 mt-24">
        <div className="container mx-auto p-6 w-fit bg-white  rounded-md shadow-md">
          <h1 className="text-3xl font-medium text-[#DD0786] mb-4 text-center">
            Contact Us
          </h1>
          <p className="text-lg text-center text-gray-700 mb-8">
            Lorem ipsum dolor sit amet consectetur adipisicing elit.
          </p>
          <form className="max-w-lg mx-auto" onSubmit={handleSubmit}>
            <div className="grid grid-cols-2 gap-4">
              <div className="col-span-2 sm:col-span-1">
                <label
                  htmlFor="name"
                  className="block text-sm font-medium text-gray-700"
                >
                  Name
                </label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  placeholder="Enter Name"
                  onChange={(e) => setName(e.target.value)}
                  className="mt-1 p-4 focus:outline-pink-500 focus:border-pink-500 block w-full shadow-sm sm:text-sm border border-gray-300 rounded-md"
                />
              </div>
              <div className="col-span-2 sm:col-span-1">
                <label
                  htmlFor="phone"
                  className="block text-sm font-medium text-gray-700"
                >
                  Phone
                </label>
                <input
                  type="text"
                  id="phone"
                  name="phone"
                  placeholder="Enter Phone"
                  onChange={(e) => setPhone(e.target.value)}
                  className="mt-1 p-4 focus:outline-pink-500 focus:border-pink-500 block w-full shadow-sm sm:text-sm border border-gray-300 rounded-md"
                />
              </div>
              <div className="col-span-2">
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-700"
                >
                  Email
                </label>
                <input
                  type="email"
                  id="email"
                  placeholder="Enter Email"
                  name="email"
                  onChange={(e) => setEmail(e.target.value)}
                  className="mt-1 p-4 focus:outline-pink-500 focus:border-pink-500 block w-full shadow-sm sm:text-sm border border-gray-300 rounded-md"
                />
              </div>
              <div className="col-span-2">
                <label
                  htmlFor="services"
                  className="block text-sm font-medium text-gray-700"
                >
                  Services
                </label>
                <select
                  name="services"
                  id="services"
                  className="w-full rounded-md border border-gray-300 focus:outline-pink-500  bg-white py-3 px-6 text-sm font-regular text-gray-400 outline-none  appearance-none"
                  value={selectedService}
                  onChange={(e) => setSelectedService(e.target.value)}
                >
                  <option value="" className="text-gray-400">
                    Select a service
                  </option>
                  {serviceItems.map((item) => (
                    <option key={item.id} value={item.id}>
                      {item.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-span-2">
                <label
                  htmlFor="message"
                  className="block text-sm font-medium text-gray-700"
                >
                  Message
                </label>
                <textarea
                  id="message"
                  name="message"
                  placeholder="Write Your Message...."
                  onChange={(e) => setMessage(e.target.value)}
                  className="mt-1 p-4 focus:outline-pink-500 focus:border-pink-500 block w-full shadow-sm sm:text-sm border border-gray-300 rounded-md"
                  rows="4"
                ></textarea>
              </div>
              <div className="col-span-2">
                <button
                  type="submit"
                  className="cursor-pointer mt-2 text-white rounded-md flex  w-full item-center justify-center p-2 bg-gradient-to-bl from-pink-400 to-pink-500 font-semibold hover:bg-pink-600"
                >
                  Send Message
                </button>
              </div>
            </div>
          </form>
          <ToastContainer />
        </div>
        <Shopping />
      </div>{" "}
    </>
  );
};

export default Contact;
