import { React, useEffect } from "react";
import Services from "../component/Services";
import Ourproduct from "../component/Ourproduct";
import Ourservices from "../component/Ourservices";
import Whyus from "../component/Whyus";
import Blog from "../component/Blog";
import Satisfied from "../component/Satisfied";
import Gallery from "../component/Gallery";
import { Link } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import HeroSection from "../component/HeroSection";
import ShoppingCart from "../component/Shopping";
import { Helmet } from "react-helmet";

const Home = () => {
  useEffect(() => {
    AOS.init({ duration: 2000 }); // Initialize AOS with desired options
  }, []);

  return (
    <>
      <Helmet>
        <title>Presu's Skincare | Best Skincare Center in Chitwan</title>
        <meta
          name="description"
          content="Welcome to Presu's Skincare, your trusted skincare partner in Chitwan. Explore our services and products to enhance your skin health."
        />
        <meta
          name="keywords"
          content="Presu's Skincare, skincare, Chitwan, beauty, skincare products"
        />
      </Helmet>
      <HeroSection />
      <ShoppingCart />
      <Services />
      <Ourproduct />
      <Ourservices />
      <Whyus />
      <Link to="blog">
        <Blog />
      </Link>
      <Satisfied />
      <Gallery />
    </>
  );
};

export default Home;
