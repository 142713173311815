import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import Shopping from "../component/Shopping";
import backgroundImage from "../../assets/4.png";
import url from "../../utils/useAxios";
import { FaStar } from "react-icons/fa";
import { BASE_URL } from "../../context/AuthContext";
const api = url();

function Blog() {
  const [blogItems, setBlogItems] = useState([]);
  const bgImg = `url(${backgroundImage})`;

  const getData = async () => {
    try {
      const res = await api.get(`/blog?page=1`);
      setBlogItems(res.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <section className="py-10 px-4" style={{ backgroundImage: bgImg }}>
      <div className="flex flex-col items-center justify-center">
        <div className="flex justify-center font-bold font-dancing text-4xl text-[#DD0786] px-4">
          Our Articles
        </div>
        <div className="flex justify-center items-center w-28">
          <div className="flex-grow bg-gradient-to-r from-purple-500 to-pink-500 rounded-full w-8 h-1"></div>
          <FaStar className="mx-2 text-pink-500" />
          <div className="flex-grow bg-gradient-to-r from-pink-500 to-purple-500 rounded-full w-8 h-1"></div>
        </div>
      </div>

      <div className="grid grid-flow-row gap-8 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
        {blogItems.map((post, index) => (
          <div
            key={index}
            className="my-8 rounded shadow-lg bg-white duration-300 hover:-translate-y-1"
          >
            <a href={post._href} className="cursor-pointer">
              <figure>
                <img
                  src={`${BASE_URL}${post.image}`}
                  alt="Post Image"
                  className="rounded-t h-72 w-full object-cover"
                />
                <figcaption className="p-4">
                  <p className="text-lg mb-1 font-bold leading-relaxed text-gray-800">
                    {post.title}
                  </p>
                  <Link
                    to={`/blog/${post.id}`}
                    className="cursor-pointer mt-2 text-white rounded-md flex  w-full item-center justify-center p-2 bg-gradient-to-bl from-pink-400 to-pink-500 font-semibold"
                  >
                    Read More
                  </Link>
                </figcaption>
              </figure>
            </a>
          </div>
        ))}
      </div>
    </section>
  );
}

export default Blog;
