import React, { useState, useEffect } from "react";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import url from "../../utils/useAxios";
import { useNavigate } from "react-router-dom";

const Appointments = ({classname}) => {
  const api = url()

  // State variables
  const [serviceItems, setServiceItems] = useState([]);
  const [step, setStep] = useState(1);
  const [date, setDate] = useState("");
  const [time, setTime] = useState("");
  const [address, setAddress] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [selectedService, setSelectedService] = useState("");
  const [appointments, setAppointments] = useState([]);
  const [user, setUser] = useState([]);
  const [submitted, setSubmitted] = useState(false);
  const [selected, setSelected] = useState("");
  const [username, setUsername] = useState("");
  const navigate = useNavigate()
  // Fetch services data
  const getData = async () => {
    try {
      const res = await api.get(
        `/service/?page=1`
      );
      setServiceItems(res.data.service);
      console.log(res.data.service);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getData();
    console.log("services");
  }, []);

  const getUser = async () => {
    try {
      const res = await api.get(
        "/takeappointment"
      );
      setUser(res.data);
      console.log(res.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = {
      username,
      date,
      time,
      email,
      service: selectedService,
      address,
      name,
      phone: phone,
    };

    try {
      if(username !== "")
      {
      const formData = new FormData()
      formData.append('username', username)
      formData.append('service', selectedService)
      formData.append('date', date)
      formData.append('time', time)
      api.post('/takeappointment',formData)
      }
      else{
      await api.post(
        "/takeappointment",
        formData
      );
      setSubmitted(true);
      toast.success('Booked Succesfully!');
      navigate('/')
      }
    } catch (error) {
      console.error("Error posting data:", error);
    }
  };

  // Handle next step
  const handleNextStep = () => {
    if (step === 2) {
      setStep(step + 2);
    } else {
      setStep(step + 1);
    }
  };
  

  // Handle previous step
  const handlePreviousStep = () => {
    setStep(step - 1);
  };




  return (
    <>
    <div className={classname}>
      <div className="flex items-center justify-between">
        <div className={`mx-auto w-full max-w-full bg-white ${!classname && `shadow p-4 rounded`} `}>
          {step === 1 && (
            <form onSubmit={handleNextStep}>
              <h2 className="text-2xl text-center font-semibold mb-6 text-pink-600">
                Create Appointment
              </h2>
              <div className="mb-5">
                <label
                  htmlFor="services"
                  className="mb-3 block text-base font-regular text-[#07074D]"
                >
                  Services
                </label>
                <select
                  name="services"
                  id="services"
                  className="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-regular text-[#6B7280] outline-none focus:border-[#ff73ab] focus:shadow-md appearance-none"
                  value={selectedService}
                  onChange={(e) => setSelectedService(e.target.value)}
                  required
                >
                  <option value="">Select a service</option>
                  {serviceItems?.map((item) => {
                    return (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="-mx-3 flex flex-wrap">
                <div className="w-full px-3 sm:w-1/2">
                  <div className="mb-5">
                    <label
                      htmlFor="date"
                      className="mb-3 block text-base font-regular text-[#07074D]"
                    >
                      Date
                    </label>
                    <input
                      type="date"
                      name="date"
                      id="date"
                      onChange={(e) => setDate(e.target.value)}
                      className="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-regular text-[#6B7280] outline-none focus:border-[#ff73ab] focus:shadow-md"
                      value={date}
                      required
                    />
                  </div>
                </div>
                <div className="w-full px-3 sm:w-1/2">
                  <div className="mb-5">
                    <label
                      htmlFor="time"
                      className="mb-3 block text-base font-regular text-[#07074D]"
                    >
                      Time
                    </label>
                    <input
                      type="time"
                      name="time"
                      id="time"
                      className="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-regular text-[#6B7280] outline-none focus:border-[#ff73ab] focus:shadow-md"
                      value={time}
                      onChange={(e) => setTime(e.target.value)}
                      required
                    />
                  </div>
                </div>
              </div>

              <div className="flex justify-between">
                <button></button>
                <button
                  type="submit"
                  className="bg-pink-600 hover:bg-pink-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                >
                  <FaArrowRight />
                </button>
              </div>
            </form>
          )}

          {step === 2 && (
            <form onSubmit={handleNextStep}>
              <h2 className="text-2xl text-center font-semibold mb-6 text-pink-600">
                Enter Username
              </h2>
              <div className="mb-1">
                <label
                  htmlFor="services"
                  className="mb-3 block text-base font-regular text-[#07074D]"
                >
                  Username
                </label>
                <input
                  type="text"
                  name="username"
                  id="username"
                  placeholder="Username"
                  className="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-regular text-[#6B7280] outline-none focus:border-[#ff73ab] focus:shadow-md"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  required
                />
              </div>
              <p className="flex text-center mb-5 items-center justify-end">Don't have <span onClick={() => setStep(step+1)} className="ml-1 mr-1 text-pink-400 underline cursor-pointer hover:text-pink-500 ">username</span>?</p>
              <div className="flex justify-between">
                <button
                  className="bg-pink-600 hover:bg-pink-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                  onClick={handlePreviousStep}
                >
                  <FaArrowLeft />
                </button>
                <button
                  type="submit" 
                  className="bg-pink-600 hover:bg-pink-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                >
                  <FaArrowRight />
                </button>
              </div>
            </form>
          )}

          {step === 3 && (
            <form onSubmit={handleNextStep}>
              <h2 className="text-2xl text-center font-semibold mb-6 text-pink-600">
                Personal Information
              </h2>
              <div className="mb-5">
                <label
                  htmlFor="name"
                  className="mb-3 block text-base font-regular text-[#07074D]"
                >
                  Full Name
                </label>
                <input
                  type="text"
                  name="name"
                  id="name"
                  placeholder="Full Name"
                  className="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-regular text-[#6B7280] outline-none focus:border-[#ff73ab] focus:shadow-md"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                />
              </div>
              <div className="-mx-3 flex flex-wrap">
                <div className="w-full px-3 sm:w-1/2">
                  <div className="mb-5">
                    <label
                      htmlFor="phone"
                      className="mb-3 block text-base font-regular text-[#07074D]"
                    >
                      Phone Number
                    </label>
                    <input
                      type="text"
                      name="phone"
                      id="phone"
                      placeholder="Enter phone number"
                      className="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-regular text-[#6B7280] outline-none focus:border-[#ff73ab] focus:shadow-md"
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                      required
                    />
                  </div>
                </div>
                <div className="w-full px-3 sm:w-1/2">
                  <div className="mb-5">
                    <label
                      htmlFor="email"
                      className="mb-3 block text-base font-regular text-[#07074D]"
                    >
                      Email Address
                    </label>
                    <input
                      type="email"
                      name="email"
                      id="email"
                      placeholder="Enter email"
                      className="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-regular text-[#6B7280] outline-none focus:border-[#ff73ab] focus:shadow-md"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      required
                    />
                  </div>
                </div>
              </div>
              <div className="mb-5">
                <label
                  htmlFor="name"
                  className="mb-3 block text-base font-regular text-[#07074D]"
                >
                  Address
                </label>
                <input
                  type="text"
                  name="address"
                  id="address"
                  placeholder="Address"
                  className="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-regular text-[#6B7280] outline-none focus:border-[#ff73ab] focus:shadow-md"
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                  required
                />
              </div>
              <div className="flex justify-between">
                <button
                  className="bg-pink-600 hover:bg-pink-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                  onClick={handlePreviousStep}
                >
                  <FaArrowLeft />
                </button>
                <button
                  type="submit"
                  className="bg-pink-600 hover:bg-pink-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                >
                  <FaArrowRight />
                </button>
              </div>
            </form>
          )}

        {step === 4 && (
          <form onSubmit={handleSubmit}>
            <h2 className="text-2xl text-center font-semibold mb-6 text-pink-600">
              Confirm Appointment
            </h2>

            <div className="mb-5">
              <table className="w-full">
                <tbody>
                  {username && (
                    <tr>
                      <td className="py-2">
                        <strong className="text-gray-600">Username:</strong>
                      </td>
                      <td className="py-2">{username}</td>
                    </tr>
                  )}
                  {name && (
                    <tr>
                      <td className="py-2">
                        <strong className="text-gray-600">Name:</strong>
                      </td>
                      <td className="py-2">{name}</td>
                    </tr>
                  )}
                  {phone && (
                    <tr>
                      <td className="py-2">
                        <strong className="text-gray-600">Phone:</strong>
                      </td>
                      <td className="py-2">{phone}</td>
                    </tr>
                  )}
                  {email && (
                    <tr>
                      <td className="py-2">
                        <strong className="text-gray-600">Email:</strong>
                      </td>
                      <td className="py-2">{email}</td>
                    </tr>
                  )}
                  {selectedService && (
                    <tr>
                      <td className="py-2">
                        <strong className="text-gray-600">Service:</strong>
                      </td>
                      <td className="py-2">{selectedService}</td>
                    </tr>
                  )}
                  {date && (
                    <tr>
                      <td className="py-2">
                        <strong className="text-gray-600">Date:</strong>
                      </td>
                      <td className="py-2">{date}</td>
                    </tr>
                  )}
                  {time && (
                    <tr>
                      <td className="py-2">
                        <strong className="text-gray-600">Time:</strong>
                      </td>
                      <td className="py-2">{time}</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>

            <div className="flex justify-between">
              <button
                className="bg-pink-600 hover:bg-pink-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                onClick={handlePreviousStep}
              >
                <FaArrowLeft />
              </button>
              <button
                type="submit"
                className="bg-pink-600 hover:bg-pink-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              >
                Book Appointment
              </button>
            </div>
          </form>
        )}

        </div>
      </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default Appointments;
