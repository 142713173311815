import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import Shopping from "../component/Shopping";
import url from "../../utils/useAxios";
import { BASE_URL } from "../../context/AuthContext";
import { FaStar } from "react-icons/fa";
import { Helmet } from "react-helmet";
const api = url();

function Blog() {
  const [blogItems, setBlogItems] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const getData = async (page) => {
    try {
      const res = await api.get(`/blog?page=${page}`);
      setBlogItems(res.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getData(currentPage); // Fetch data for the current page
  }, [currentPage]);

  const extractTitles = () => {
    return blogItems.map((post) => post.title).join(", ");
  };
  return (
    <>
      <Helmet>
        <title>Skincare Blog | Presu's Skincare</title>
        <meta
          name="description"
          content="Stay updated with the latest skincare tips, trends, and advice on the Presu's Skincare blog. Enhance your skincare knowledge."
        />
        <meta
          name="keywords"
          content={`Skincare blog, skincare tips, Presu's Skincare, Chitwan, skincare advice, ${extractTitles()}`}
        />
      </Helmet>

      <section className="py-10 px-8 mt-24">
        <div className="flex flex-col items-center justify-center mb-2">
          <div className="flex justify-center font-bold font-dancing text-4xl text-[#DD0786] px-4">
            Our Blogs
          </div>
          <div className="flex justify-center items-center w-28">
            <div className="flex-grow bg-gradient-to-r from-purple-500 to-pink-500 rounded-full w-8 h-1"></div>
            <FaStar className="mx-2 text-pink-500" />
            <div className="flex-grow bg-gradient-to-r from-pink-500 to-purple-500 rounded-full w-8 h-1"></div>
          </div>
        </div>
        <div className="grid grid-flow-row gap-8 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
          {blogItems.map((post, index) => (
            <div
              key={index}
              className="my-8 rounded shadow-lg bg-white duration-300 hover:-translate-y-1"
            >
              <a href={post._href} className="cursor-pointer">
                <figure>
                  <img
                    src={`${BASE_URL}${post.image}`}
                    alt="Post Image"
                    className="rounded-t h-72 w-full object-cover"
                  />
                  <figcaption className="p-4">
                    <p className="text-lg mb-1 font-bold leading-relaxed text-gray-800">
                      {post.title}
                    </p>
                    <Link
                      to={`/blog/${post.id}`}
                      className="cursor-pointer mt-2 text-white rounded-md flex  w-full item-center justify-center p-2 bg-gradient-to-bl from-pink-400 to-pink-500 font-semibold"
                    >
                      Read More
                    </Link>
                  </figcaption>
                </figure>
              </a>
            </div>
          ))}
        </div>
        <Shopping />
      </section>
    </>
  );
}

export default Blog;
