import React, { useState, useEffect } from "react";
import Shopping from "../component/Shopping";
import url from "../../utils/useAxios";
import { BASE_URL } from "../../context/AuthContext";
import { Helmet } from "react-helmet";
import { FaTimes } from "react-icons/fa";
const api = url();

const Gallery = () => {
  const [galleryItems, setGalleryItems] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const getData = async () => {
    try {
      const res = await api.get(`/gallery`);
      console.log(res.data); // Check the response data
      setGalleryItems(res.data);
    } catch (error) {
      console.error(error);
    }
  };
  const keywords = [];
  galleryItems.forEach((item) => {
    keywords.push(item.title);
  });
  useEffect(() => {
    getData();
    console.log("gallery");
  }, []);

  if (!galleryItems || galleryItems.length === 0) {
    return <div>Loading...</div>;
  }

  const openModal = (image) => {
    setSelectedImage(image);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setSelectedImage(null);
    setIsModalOpen(false);
  };

  return (
    <>
      <Helmet>
        <title>Our Gallery | Presu's Skincare</title>
        <meta
          name="description"
          content="Explore our skincare gallery featuring before-and-after photos of satisfied clients. See the transformation for yourself."
        />
        <meta
          name="keywords"
          content={`Skincare gallery, before and after, Presu's Skincare, Chitwan, client transformations, ${keywords.join(', ')}`}
        />
      </Helmet>

      <div className="p-8 mt-24">
        <Shopping />

        <h1 className="text-4xl font-bold text-pink-500 text-center mb-8">
          Our Portfolio
        </h1>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
          {galleryItems.map((item) => (
            <div
              key={item.id}
              className="bg-white rounded-lg overflow-hidden shadow-md"
            >
              <div className="relative">
                <img
                  src={`${BASE_URL}${item.image}`}
                  alt="Gallery"
                  className="w-full h-48 object-cover"
                />
                <div className="absolute top-0 left-0 right-0 bottom-0 bg-gray-800 bg-opacity-30 opacity-0 hover:opacity-100 transition-opacity duration-300">
                  <div className="flex justify-center items-center h-full">
                    <button
                      onClick={() => openModal(item)}
                      className="text-white font-bold text-lg"
                    >
                      {item.title}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
        {isModalOpen && selectedImage && (
          <div className="fixed top-0 left-0 right-0 bottom-0 flex items-center justify-center gallery-modal bg-gray-900 bg-opacity-50">
            <div className="max-w-screen-lg h-[70vh]   flex flex-col items-end justify-center">
              <button
                onClick={closeModal}
                className="mt-1 px-2 py-2 items-end text-white bg-pink-500 border border-pink-500 translate-y-[36px] -translate-x-[2px] rounded-full focus:outline-none hover:bg-pink-600  hover:border-pink-500 "
              >
                <FaTimes />
              </button>
              <img
                src={`${BASE_URL}${selectedImage.image}`}
                alt={selectedImage.alt}
                className="max-w-full max-h-[70vh] rounded-md shadow-md"
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Gallery;
