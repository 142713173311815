import { FaArrowLeft, FaArrowRight, FaCartPlus } from "react-icons/fa";
import React, { useState, useEffect } from "react";
import image from "../../assets/Thatmatt.png";
import { useContext } from "react";
import AuthContext, { BASE_URL } from "../../context/AuthContext";
import empty from "../../assets/empty.jpeg";
import { Link } from "react-router-dom";
import url from "../../utils/useAxios";
import { toast } from "react-toastify";

const Shopping = () => {
  const api = url();
  const [isCartOpen, setIsCartOpen] = useState(false);
  const toggleCart = () => {
    setIsCartOpen(!isCartOpen);
  };
  const [step, setStep] = useState(1);
  const [personalInfo, setPersonalInfo] = useState({});
  const [formSubmitError, setFormSubmitError] = useState(null);

  const handleNextStep = () => {
    setStep((prevStep) => prevStep + 1);
  };

  const handlePreviousStep = () => {
    setStep((prevStep) => prevStep - 1);
  };

  const [username, setUsername] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [order, setOrder] = useState();
  const [address, setAddress] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = {
      username,
      email,
      address,
      name,
      phone_number: phone,
    };

    try {
      if (username !== "") {
        const formData = new FormData();
        formData.append("username", username);
        api.post(`/checkout/${order.id}`, formData).then(() => {
          setUsername("");
          setSubmitted(true);
          setIsCartOpen(false);
          setCartItems([]);
          setStep(1);
          toast.success("Your order has been placed!");
        });
      } else {
        await api.post(`/checkout/${order.id}`, formData).then(() => {
          setAddress("");
          setName("");
          setEmail("");
          setPhone("");
          setSubmitted(true);
          setStep(1);
          setCartItems([]);
          toast.success("Your order has been placed!");
        });
      }
    } catch (error) {
      console.error("Error posting data:", error);
    }
  };

  const [cartItems, setCartItems] = useState([]);
  const { test } = useContext(AuthContext);
  console.log(test);
  const getData = async () => {
    try {
      const res = await api.get("/takeorder");
      console.log(res.data);
      const orderItems = res.data.order_items || []; // Use optional chaining to handle undefined data
      setOrder(res.data.order);
      setCartItems(orderItems);
      console.log(orderItems);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getData();
    console.log("order items");
  }, [test]);

  const cartItemsLength = cartItems?.length || 0;

  const { addtest } = useContext(AuthContext);
  const handleAddItem = async (productId, quantity) => {
    console.log(productId, quantity);
    try {
      const response = await api.post(
        `/add_item_to_order/${productId}`,
        { quantity } // Pass the quantity in the request body
      );

      if (response.status === 200) {
        setCartItems((prevItems) =>
          prevItems.map((item) => {
            if (item.product.id === productId) {
              return { ...item, quantity: quantity }; // Update the quantity of the matching item
            }
            return item;
          })
        );

        addtest();
        changetest();
        // Call the addtest function
      } else {
        console.error("Failed to update item quantity", response.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const { changetest } = useContext(AuthContext);
  const handleDecreaseItem = async (productId) => {
    console.log(productId);
    try {
      const response = await api.post(`/remove_item_from_order/${productId}`);

      if (response.status === 200) {
        setCartItems((prevItems) =>
          prevItems.filter((item) => item.product.id !== productId)
        );
        changetest();
        addtest();

        // Call the removeItem function directly
      } else {
        console.error("Failed to remove item from cart", response.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  // remove (delete directly) the items from cart
  const { deletetest } = useContext(AuthContext);
  const handleDeleteItem = async (productId) => {
    try {
      const response = await api.post(`/remove_item_from_order/${productId}`);

      if (response.status === 200) {
        setCartItems((prevItems) =>
          prevItems.filter((item) => item.id !== productId)
        );
        deletetest();
      } else {
        console.error("Failed to remove item from cart", response.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  // calculate the total price of the cart items
  const calculateTotal = () => {
    return cartItems.reduce(
      (total, item) => total + item.price * item.quantity,
      0
    );
  };

  return (
    <>
      {isCartOpen && (
        <div
          className="relative shopping"
          aria-labelledby="slide-over-title"
          role="dialog"
          aria-modal="true"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
                <div className="pointer-events-auto w-screen max-w-sm">
                  <div className="flex h-full flex-col overflow-hidden bg-white shadow-xl">
                    <div className="flex-1 overflow-y-auto px-4 py-6 sm:px-6">
                      <div className="flex items-start justify-between">
                        <h2
                          className="text-lg font-medium text-pink-600"
                          id="slide-over-title"
                        >
                          Shopping carts
                        </h2>
                        <div className="ml-3 flex h-7 items-center">
                          <button
                            type="button"
                            className="-m-2 p-2 text-gray-400 hover:text-gray-500"
                            onClick={toggleCart}
                          >
                            <span className="sr-only">Close panel</span>
                            <svg
                              className="h-6 w-6"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth="1.5"
                              stroke="currentColor"
                              aria-hidden="true"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M6 18L18 6M6 6l12 12"
                              />
                            </svg>
                          </button>
                        </div>
                      </div>
                      {step === 1 && (
                        <div className="mt-8">
                          {cartItems.length === 0 ? (
                            <div>
                              <img src={empty} alt="" />
                              <Link
                                to="/product"
                                className="flex items-center justify-center rounded-md border border-transparent bg-pink-500 p-2 text-base font-medium text-white shadow-sm hover:bg-pink-700"
                              >
                                Shopping
                              </Link>
                            </div>
                          ) : (
                            <div className="flow-root">
                              <ul
                                role="list"
                                className="-my-6 divide-y divide-gray-200"
                              >
                                {cartItems?.map((item) => (
                                  <li key={item.id} className="flex py-6">
                                    <div className="h-[60px] w-[60px] flex-shrink-0 overflow-hidden rounded-md border p-[3px] border-gray-200">
                                      <img
                                        src={`${BASE_URL}${item.product.image}`}
                                        alt={item.name}
                                        className="h-full w-full object-cover object-center rounded-md"
                                      />
                                    </div>
                                    <div className="ml-4 flex flex-1 flex-col">
                                      <div>
                                        <div className="flex justify-between items-center text-base font-medium text-gray-900">
                                          <h3>
                                            <a href="#" className="text-sm">
                                              {item.product.name}
                                            </a>
                                          </h3>
                                          <p className="ml-4 text-sm text-gray-600">
                                            Rs.
                                            {item.price *
                                              item.quantity.toFixed(2)}
                                          </p>
                                        </div>
                                      </div>
                                      <div className="flex flex-1 items-end justify-between text-sm">
                                        <div className="flex">
                                          <button
                                            type="button"
                                            className="p-1 border border-gray-300 w-6 h-6 rounded-md flex justify-center items-center  hover:bg-gray-200"
                                            onClick={() =>
                                              handleDecreaseItem(item.id)
                                            }
                                          >
                                            <span className="sr-only">
                                              Reduce quantity
                                            </span>
                                            <svg
                                              className="h-4 w-4"
                                              fill="none"
                                              viewBox="0 0 24 24"
                                              stroke="currentColor"
                                            >
                                              <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth={2}
                                                d="M20 12H4"
                                              />
                                            </svg>
                                          </button>
                                          <span className="mx-2 border border-gray-300 rounded-md text-center flex justify-center items-center w-6 h-6">
                                            {item.quantity}
                                          </span>
                                          <button
                                            type="button"
                                            className="p-1 border border-gray-300 w-6 h-6 rounded-md flex justify-center items-center  hover:bg-gray-200"
                                            onClick={() =>
                                              handleAddItem(item.id)
                                            }
                                          >
                                            <span className="sr-only">
                                              Increase quantity
                                            </span>
                                            <svg
                                              className="h-4 w-4"
                                              fill="none"
                                              viewBox="0 0 24 24"
                                              stroke="currentColor"
                                            >
                                              <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth={2}
                                                d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                                              />
                                            </svg>
                                          </button>
                                        </div>
                                        <div className="flex">
                                          <button
                                            type="button"
                                            className="font-medium text-pink-600 hover:text-pink-500"
                                            onClick={() =>
                                              handleDeleteItem(item.id)
                                            }
                                          >
                                            Remove
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </li>
                                ))}
                              </ul>
                            </div>
                          )}
                        </div>
                      )}

                      {step === 2 && (
                        <form onSubmit={handleNextStep}>
                          <div className="mb-1 mt-6">
                            <label
                              htmlFor="services"
                              className="mb-3 block text-base font-regular text-[#07074D]"
                            >
                              Username
                            </label>
                            <input
                              type="text"
                              name="username"
                              id="username"
                              placeholder="Username"
                              value={username}
                              onChange={(e) => setUsername(e.target.value)}
                              className="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-regular text-[#6B7280] outline-none focus:border-[#ff73ab] focus:shadow-md"
                              required
                            />
                          </div>
                          <p className="flex text-center mb-5 items-center justify-end">
                            Don't have{" "}
                            <span
                              onClick={() => setStep(step + 1)}
                              className="ml-1 mr-1 text-pink-400 underline cursor-pointer hover:text-pink-500 "
                            >
                              username
                            </span>
                            ?
                          </p>
                        </form>
                      )}

                      {step === 3 && (
                        <div className="mt-8">
                          <h2 className="text-lg font-medium text-gray-900">
                            Personal Information
                          </h2>
                          <form onSubmit={handleSubmit}>
                            <div className="mt-6">
                              <label
                                htmlFor="name"
                                className="block text-sm font-medium text-gray-700"
                              >
                                Name
                              </label>
                              <input
                                type="text"
                                id="name"
                                name="name"
                                value={name}
                                required
                                onChange={(e) => setName(e.target.value)}
                                className="mt-1 block w-full border-[1px] border-gray-300 p-2 rounded-md shadow-sm focus:ring-pink-500 focus:border-pink-500 sm:text-sm"
                              />
                            </div>
                            <div className="mt-6">
                              <label
                                htmlFor="phone"
                                className="block text-sm font-medium text-gray-700"
                              >
                                Phone
                              </label>
                              <input
                                type="number"
                                id="phone"
                                name="phone"
                                className="mt-1 block w-full border-[1px] border-gray-300 p-2 rounded-md shadow-sm focus:ring-pink-500 focus:border-pink-500 sm:text-sm"
                                value={phone}
                                required
                                onChange={(e) => setPhone(e.target.value)}
                              />
                            </div>
                            <div className="mt-6">
                              <label
                                htmlFor="email"
                                className="block text-sm font-medium text-gray-700"
                              >
                                Email
                              </label>
                              <input
                                type="email"
                                id="email"
                                name="email"
                                required
                                className="mt-1 block w-full border-[1px] border-gray-300 p-2 rounded-md shadow-sm focus:ring-pink-500 focus:border-pink-500 sm:text-sm"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                              />
                            </div>
                            <div className="mt-6">
                              <label
                                htmlFor="address"
                                className="block text-sm font-medium text-gray-700"
                              >
                                Address
                              </label>
                              <input
                                type="text"
                                id="address"
                                name="address"
                                required
                                className="mt-1 block w-full border-[1px] border-gray-300 p-2 rounded-md shadow-sm focus:ring-pink-500 focus:border-pink-500 sm:text-sm"
                                value={address}
                                onChange={(e) => setAddress(e.target.value)}
                              />
                            </div>
                          </form>
                        </div>
                      )}
                    </div>
                    {step === 1 && (
                      <div className="border-t border-gray-200 px-4 py-6 sm:px-6">
                        <div className="flex justify-between text-base font-medium text-gray-900">
                          <p>Subtotal</p>
                          <p>Rs. {calculateTotal().toFixed(2)}</p>
                        </div>
                        <div className="mt-6">
                          <button
                            className="flex items-center justify-center rounded-md border border-transparent bg-pink-500 p-2 text-base font-medium text-white shadow-sm hover:bg-pink-700"
                            onClick={handleNextStep}
                          >
                            Checkout
                          </button>
                        </div>
                      </div>
                    )}
                    {step === 2 && (
                      <div className="border-t border-gray-200 px-4 py-6 sm:px-6">
                        <div className="flex justify-between text-base font-medium text-gray-900">
                          <p>Subtotal</p>
                          <p>Rs. {calculateTotal().toFixed(2)}</p>
                        </div>
                        <div className="mt-6 flex justify-between">
                          <button
                            type="button"
                            className="flex items-center justify-center rounded-md border border-gray-300 p-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50"
                            onClick={handlePreviousStep}
                          >
                            <FaArrowLeft />
                          </button>
                          <button
                            type="submit"
                            className="ml-4 flex items-center justify-center rounded-md border border-transparent bg-pink-500 p-2 text-base font-medium text-white shadow-sm hover:bg-pink-600"
                            onClick={handleSubmit}
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    )}
                    {step === 3 && (
                      <div className="border-t border-gray-200 px-4 py-6 sm:px-6">
                        <div className="flex justify-between text-base font-medium text-gray-900">
                          <p>Subtotal</p>
                          <p>Rs. {calculateTotal().toFixed(2)}</p>
                        </div>
                        <div className="mt-6 flex justify-between">
                          <button
                            type="button"
                            className="flex items-center justify-center rounded-md border border-gray-300 p-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50"
                            onClick={handlePreviousStep}
                          >
                            <FaArrowLeft />
                          </button>
                          <button
                            type="submit"
                            onClick={handleSubmit}
                            className="ml-4 flex items-center justify-center rounded-md border border-transparent bg-pink-500 p-2 text-base font-medium text-white shadow-sm hover:bg-pink-600"
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {!isCartOpen && (
        <button
          onClick={toggleCart}
          title="Shopping Cart"
          className="fixed z-50 bottom-10 right-4 bg-pink-500 w-16 h-16 p-2 rounded-full drop-shadow-lg flex justify-center items-center text-white text-2xl hover:bg-pink-700 ease-in-out transition-shadow hover:drop-shadow-2xl"
        >
          <span className="absolute top-0 right-0 bg-white text-pink-500 w-6 h-6 rounded-full flex justify-center items-center text-xs font-bold">
            {cartItems.length}
          </span>
          <FaCartPlus />
        </button>
      )}
    </>
  );
};

export default Shopping;
