import { Routes, Route } from "react-router-dom";
import "./App.css";
import Footer from "./component/Footer";
import Header from "./component/Header";
import About from "./pages/main/About";
import Home from "./pages/main/Home";
import Services from "./pages/main/Services";
import Blog from "./pages/main/Blog";
import Gallery from "./pages/main/Gallery";
import Appointment from "./pages/main/Appointments";
import Product from "./pages/main/Product";
import Contact from "./pages/main/Contact";
import SingleBlog from "./pages/main/SingleBlog";
import ProductDetail from "./pages/main/ProductDetail";
import ServiceDetail from "./pages/main/ServiceDetail";
import Location from "./pages/main/Location";

function App() {
  return (
    <div className="overflow-x-hidden app">
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/services" element={<Services />} />
        <Route path="/services/:id" element={<ServiceDetail />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/blog/:id" element={<SingleBlog />} />
        <Route path="/gallery" element={<Gallery />} />
        <Route path="/appointment" element={<Appointment />} />
        <Route path="/product" element={<Product />} />
        <Route path="/product/:id" element={<ProductDetail />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/location" element={<Location />} />
        
      </Routes>

      <Footer />
    </div>
  );
}

export default App;
