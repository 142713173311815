import React, { useState, useEffect } from "react";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import axios from "axios";
import Shopping from "../component/Shopping";
import Appointment from "../component/Appointment";
import url from "../../utils/useAxios";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
const api = url();

const Appointments = () => {
  // State variables
  const [serviceItems, setServiceItems] = useState([]);
  const [step, setStep] = useState(1);
  const [date, setDate] = useState("");
  const [time, setTime] = useState("");
  const [address, setAddress] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [selectedService, setSelectedService] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [selected, setSelected] = useState("");
  const navigate = useNavigate();

  // Fetch services data
  const getData = async () => {
    try {
      const res = await api.get(`/service/?page=1`);
      setServiceItems(res.data.service);
      console.log(res.data.service);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getData();
    console.log("services");
  }, []);

  const [appointments, setAppointments] = useState([]);
  const getDatas = async () => {
    try {
      const res = await api.get("/customerappointment");
      setAppointments(res.data);
      console.log(res);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    getDatas();
    console.log("appointmenstsssssssss");
  }, []);
  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = {
      date,
      time,
      email,
      service: selectedService,
      address,
      name,
      phone_number: phone,
    };

    try {
      await api.post("/takeappointment", formData);
      setSubmitted(true);
      toast.success("Booked Successfully!");
      navigate("/");
    } catch (error) {
      toast.error("Error posting data:", error);
    }
  };

  // Handle next step
  const handleNextStep = () => {
    setStep(step + 1);
  };

  // Handle previous step
  const handlePreviousStep = () => {
    setStep(step - 1);
  };

  const handleSelected = (item) => {
    console.log(item.name);
    setSelected(item.name);
  };

  return (
    <>
      <Helmet>
        <title>Book an Appointment | Presu's Skincare</title>
        <meta
          name="description"
          content="Schedule an appointment with our skincare experts at Presu's Skincare in Chitwan. Achieve healthier and radiant skin."
        />
        <meta
          name="keywords"
          content="Book an appointment, skincare experts, Presu's Skincare, Chitwan, radiant skin"
        />
      </Helmet>

      <div className="flex items-center justify-between p-12 mt-20">
        <Appointment classname="mx-auto w-full max-w-full bg-white shadow p-8 rounded" />
      </div>

      <div className="p-12">
        <div className="max-w-full mx-auto bg-white shadow p-8 rounded">
          <h2 className="text-2xl text-center font-semibold mb-6 text-pink-600">
            Recent Appointments
          </h2>

          <div className="overflow-x-auto">
            <table className="w-full text-center text-sm font-urban ">
              <thead className="">
                <tr className="bg-gray-50 text-gray-600 ">
                  <th className="p-2">Date</th>
                  <th className="p-2">Time</th>
                  <th className="p-2">Username</th>
                  <th className="p-2">Name</th>
                  <th className="p-2">Address</th>
                  <th className="p-2">Email</th>
                  <th className="p-2">Service</th>
                  <th className="p-2">Service Provider</th>
                  <th className="p-2">Status</th>
                </tr>
              </thead>
              <tbody>
                {appointments?.map((appointment) => (
                  <tr
                    key={appointment.id}
                    className="hover:shadow-md text-gray-600 border-b mt-2 border-gray-300"
                  >
                    <td className="px-2 py-4">{appointment.date}</td>
                    <td className="px-2 py-4">{appointment.time}</td>
                    <td className="px-2 py-4">{appointment.username}</td>
                    <td className="px-2 py-4">{appointment.name}</td>
                    <td className="px-2 py-4">{appointment.address}</td>
                    <td className="px-2 py-4">{appointment.email}</td>
                    <td className="px-2 py-4 font-semi">
                      {appointment.service.name}
                    </td>
                    <td className="px-2 py-4">
                      {appointment.service_provider.name ? (
                        appointment.service_provider.name
                      ) : (
                        <span className="text-red-500">Not Assigned</span>
                      )}
                    </td>
                    <td className="px-2 py-4">
                      {appointment.approved && appointment.complete ? (
                        <p className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-orange-100 text-orange-700">
                          Completed
                        </p>
                      ) : appointment.approved ? (
                        <p className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-700">
                          Approved
                        </p>
                      ) : appointment.reject ? (
                        <p className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-red-100 text-red-700">
                          Rejected
                        </p>
                      ) : (
                        <p className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-yellow-100 text-yellow-700">
                          Pending
                        </p>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <ToastContainer />
      <Shopping />
    </>
  );
};

export default Appointments;
