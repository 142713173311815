import React, { useEffect, useState } from "react";
import Img2 from "../../assets/abt2.png";
import Img3 from "../../assets/abt3.png";
import Shopping from "../component/Shopping";
import { Helmet } from "react-helmet";

const About = () => {
  return (
    <>
      <Helmet>
        <title>About Us | Presu's Skincare</title>
        <meta
          name="description"
          content="Learn about Presu's Skincare, your premier destination for skincare solutions in Chitwan. Discover our story and mission."
        />
        <meta
          name="keywords"
          content="About us, Presu's Skincare, skincare, Chitwan, skincare solutions"
        />
      </Helmet>

      <div className="2xl:container 2xl:mx-auto lg:py-16 lg:px-20 md:py-12 md:px-6 py-9 px-4 mt-20">
        <p className="font-normal text-sm leading-3 text-pink-400 hover:text-pink-600 cursor-pointer pb-2">
          About Us
        </p>
        <div className="flex lg:flex-row flex-col lg:gap-8 sm:gap-10 gap-12">
          <div className="w-full lg:w-6/12">
            <h2 className="w-full font-bold lg:text-4xl text-3xl lg:leading-10 leading-9">
              Presu Skin Care: Nourishing Your Skin, Naturally!
            </h2>
            <p className="font-normal text-base leading-6 text-gray-600 mt-6">
              At Presu Skin Care, we believe in the power of nature to transform
              your skin. Our premium skincare brand is dedicated to providing
              you with high-quality, natural products that nourish and
              rejuvenate your skin. With a team of expert dermatologists and
              skin specialists in Nepal, we are committed to helping you achieve
              healthier, radiant skin. Choose Presu Skin Care and embrace the
              power of nature for a healthier, more vibrant you. Your skin
              deserves the best, and we are committed to providing it. Discover
              the transformative effects of our skincare products and embark on
              a journey to radiant, nourished skin today.
              <br />
              Presu Skin Care is a trusted name in the skincare industry,
              offering a wide range of effective and safe skincare solutions. We
              prioritize natural ingredients, transparency, and integrity in our
              approach to skincare. Our carefully formulated products are
              tailored to address specific skincare concerns, ensuring that you
              receive the best care for your skin.
            </p>
          </div>
          <div className="w-full  lg:w-6/12">
            <img
              className="lg:block hidden w-full h-[420px] object-cover rounded-md"
              src={Img3}
              alt="people discussing on board"
            />
            <img
              className="lg:hidden sm:block hidden w-full rounded-md"
              src={Img2}
              alt="people discussing on board"
            />
            <img
              className="sm:hidden block w-full rounded-md"
              src={Img3}
              alt="people discussing on board"
            />
          </div>
        </div>

        <div className="px-4 py-16 mx-auto sm:max-w-xl bg-gray-50 mt-6 md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
          <div className="grid gap-8 sm:grid-cols-1 md:grid-cols-3">
            <div className="text-center md:border-r">
              <h6 className="text-5xl font-bold text-pink-400">8+</h6>
              <p className="font-bold ">Years Of Experience</p>
            </div>
            <div className="text-center md:border-r">
              <h6 className="text-5xl font-bold text-pink-400">12.9K</h6>
              <p className="font-bold ">Happy Customers</p>
            </div>
            <div className="text-center">
              <h6 className="text-5xl font-bold text-pink-400">3K</h6>
              <p className="font-bold ">Skin Transformations</p>
            </div>
          </div>
        </div>

        <div className="sm:hidden grid sm:grid-cols-3 grid-cols-2 sm:gap-8 gap-4">
          <div>
            <p className="font-semibold lg:text-2xl text-xl lg:leading-6 leading-5 text-gray-800 mt-6">
              400k User
            </p>
            <p className="font-normal text-base leading-6 text-gray-600 mt-6">
              It is a long established fact that a reader will be distracted by
              the readable content of a page when looking at its layout.
            </p>
          </div>
        </div>

        <div className="flex lg:flex-row flex-col justify-between lg:mt-20 mt-16">
          <div className="w-full lg:w-6/12">
            <h2 className="font-bold lg:text-4xl text-3xl lg:leading-9 leading-7 text-gray-800">
              Our Mission
            </h2>
            <p className="font-normal text-base leading-6 text-gray-600 mt-6 w-full lg:w-10/12 xl:w-9/12">
              <b>Delivering Natural Excellence:</b> Our mission is to deliver
              excellence through natural skincare solutions. We believe in
              harnessing the power of nature to create products that are
              effective, safe, and gentle on your skin. We carefully select
              natural ingredients known for their beneficial properties to
              provide you with the best skincare experience.
            </p>
            <p className="font-normal text-base leading-6 text-gray-600 mt-6 w-full lg:w-10/12 xl:w-9/12">
              <b>Empowering Healthy Skin:</b> We aim to empower individuals to
              take control of their skin health. Through our educational
              resources, we provide valuable insights and guidance on
              maintaining healthy skin. We believe that knowledge is the key to
              achieving long-term skincare success.
            </p>
            <p className="font-normal text-base leading-6 text-gray-600 mt-6 w-full lg:w-10/12 xl:w-9/12">
              <b>Enhancing Radiance:</b> Our goal is to help you discover the
              radiance within you. We develop products that nourish and
              revitalize your skin, unlocking its natural beauty. With Presu
              Skin Care, you can embrace your unique glow and feel confident in
              your skin.
            </p>
          </div>
          <div className="w-full lg:w-6/12">
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-1 lg:gap-12 gap-10">
              {/* <!-- Team Card --> */}
              <div className="flex p-4 shadow-md">
                <div className="mr-6">
                  <svg
                    width="36"
                    height="36"
                    viewBox="0 0 36 36"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M18 15C20.4853 15 22.5 12.9853 22.5 10.5C22.5 8.01472 20.4853 6 18 6C15.5147 6 13.5 8.01472 13.5 10.5C13.5 12.9853 15.5147 15 18 15Z"
                      stroke="#1F2937"
                      strokeWidth="2.75"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M25.5 28.5C27.9853 28.5 30 26.4853 30 24C30 21.5147 27.9853 19.5 25.5 19.5C23.0147 19.5 21 21.5147 21 24C21 26.4853 23.0147 28.5 25.5 28.5Z"
                      stroke="#1F2937"
                      strokeWidth="2.75"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M10.5 28.5C12.9853 28.5 15 26.4853 15 24C15 21.5147 12.9853 19.5 10.5 19.5C8.01472 19.5 6 21.5147 6 24C6 26.4853 8.01472 28.5 10.5 28.5Z"
                      stroke="#1F2937"
                      strokeWidth="2.75"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
                <div className="">
                  <p className="font-semibold lg:text-2xl text-xl lg:leading-6 leading-5 text-gray-800">
                    Skin Specialists
                  </p>
                  <p className="mt-2 font-normal text-base leading-6 text-gray-600">
                    INepal's most trusted and professional team of Skin
                    Specialists
                  </p>
                </div>
              </div>

              {/* <!-- Board Card --> */}
              <div className="flex p-4 shadow-md">
                <div className="mr-6">
                  <svg
                    width="36"
                    height="36"
                    viewBox="0 0 36 36"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10.5 10.5C12.1569 10.5 13.5 9.15685 13.5 7.5C13.5 5.84315 12.1569 4.5 10.5 4.5C8.84315 4.5 7.5 5.84315 7.5 7.5C7.5 9.15685 8.84315 10.5 10.5 10.5Z"
                      stroke="#1F2937"
                      strokeWidth="2.75"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M7.5 33V25.5L6 24V18C6 17.6022 6.15804 17.2206 6.43934 16.9393C6.72064 16.658 7.10218 16.5 7.5 16.5H13.5C13.8978 16.5 14.2794 16.658 14.5607 16.9393C14.842 17.2206 15 17.6022 15 18V24L13.5 25.5V33"
                      stroke="#1F2937"
                      strokeWidth="2.75"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M25.5 10.5C27.1569 10.5 28.5 9.15685 28.5 7.5C28.5 5.84315 27.1569 4.5 25.5 4.5C23.8431 4.5 22.5 5.84315 22.5 7.5C22.5 9.15685 23.8431 10.5 25.5 10.5Z"
                      stroke="#1F2937"
                      strokeWidth="2.75"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M22.5 33V27H19.5L22.5 18C22.5 17.6022 22.658 17.2206 22.9393 16.9393C23.2206 16.658 23.6022 16.5 24 16.5H27C27.3978 16.5 27.7794 16.658 28.0607 16.9393C28.342 17.2206 28.5 17.6022 28.5 18L31.5 27H28.5V33"
                      stroke="#1F2937"
                      strokeWidth="2.75"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
                <div className="">
                  <p className="font-semibold lg:text-2xl text-xl lg:leading-6 leading-5 text-gray-800">
                    Advance Technology
                  </p>
                  <p className="mt-2 font-normal text-base leading-6 text-gray-600">
                    All technologies used at Presu are USA FDA Approved
                  </p>
                </div>
              </div>

              {/* <!-- Press Card --> */}
              <div className="flex p-4 shadow-md">
                <div className="mr-6">
                  <svg
                    width="36"
                    height="36"
                    viewBox="0 0 36 36"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M28.5 7.5H7.5C5.84315 7.5 4.5 8.84315 4.5 10.5V25.5C4.5 27.1569 5.84315 28.5 7.5 28.5H28.5C30.1569 28.5 31.5 27.1569 31.5 25.5V10.5C31.5 8.84315 30.1569 7.5 28.5 7.5Z"
                      stroke="#1F2937"
                      strokeWidth="2.75"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M4.5 10.5L18 19.5L31.5 10.5"
                      stroke="#1F2937"
                      strokeWidth="2.75"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
                <div className="">
                  <p className="font-semibold lg:text-2xl text-xl lg:leading-6 leading-5 text-gray-800">
                    Best Consultation
                  </p>
                  <p className="mt-2 font-normal text-base leading-6 text-gray-600">
                    One on one in clinic consultation along with online
                    consultation facility
                  </p>
                </div>
              </div>
              {/* <!-- Board Card --> */}
              <div className="flex p-4 shadow-md">
                <div className="mr-6">
                  <svg
                    width="36"
                    height="36"
                    viewBox="0 0 36 36"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10.5 10.5C12.1569 10.5 13.5 9.15685 13.5 7.5C13.5 5.84315 12.1569 4.5 10.5 4.5C8.84315 4.5 7.5 5.84315 7.5 7.5C7.5 9.15685 8.84315 10.5 10.5 10.5Z"
                      stroke="#1F2937"
                      strokeWidth="2.75"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M7.5 33V25.5L6 24V18C6 17.6022 6.15804 17.2206 6.43934 16.9393C6.72064 16.658 7.10218 16.5 7.5 16.5H13.5C13.8978 16.5 14.2794 16.658 14.5607 16.9393C14.842 17.2206 15 17.6022 15 18V24L13.5 25.5V33"
                      stroke="#1F2937"
                      strokeWidth="2.75"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M25.5 10.5C27.1569 10.5 28.5 9.15685 28.5 7.5C28.5 5.84315 27.1569 4.5 25.5 4.5C23.8431 4.5 22.5 5.84315 22.5 7.5C22.5 9.15685 23.8431 10.5 25.5 10.5Z"
                      stroke="#1F2937"
                      strokeWidth="2.75"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M22.5 33V27H19.5L22.5 18C22.5 17.6022 22.658 17.2206 22.9393 16.9393C23.2206 16.658 23.6022 16.5 24 16.5H27C27.3978 16.5 27.7794 16.658 28.0607 16.9393C28.342 17.2206 28.5 17.6022 28.5 18L31.5 27H28.5V33"
                      stroke="#1F2937"
                      strokeWidth="2.75"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
                <div className="">
                  <p className="font-semibold lg:text-2xl text-xl lg:leading-6 leading-5 text-gray-800">
                    Certified Skin Therapists
                  </p>
                  <p className="mt-2 font-normal text-base leading-6 text-gray-600">
                    Certified and amiable team of well trained skin therapists
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Shopping />
      </div>
    </>
  );
};

export default About;
