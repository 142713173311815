import React, { useState, useEffect, useCallback } from "react";
import {
  FaHeart,
  FaSearch,
  FaStar,
  FaShoppingCart,
  FaCheckCircle,
  FaChevronDown,
  FaChevronRight,
} from "react-icons/fa";
import { Range } from "react-range";
import { Link } from "react-router-dom";
import Shopping from "../component/Shopping";
import { ToastContainer, toast } from "react-toastify";
import AuthContext, { BASE_URL } from "../../context/AuthContext";
import { useContext } from "react";
import "react-toastify/dist/ReactToastify.css";
import url from "../../utils/useAxios";
import { Helmet } from "react-helmet";
const api = url();

const fetchData = async (url) => {
  try {
    const res = await api.get(url);
    return res.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    return null;
  }
};

const Product = () => {
  const [cartItems, setCartItems] = useState([]);
  const [productItems, setProductItems] = useState([]);
  const [categories, setCategories] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [minPrice, setMinPrice] = useState(0);
  const [maxPrice, setMaxPrice] = useState(10000);
  const [hoveredItem, setHoveredItem] = useState(null);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [isFavorite, setIsFavorite] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [categoryTotalPages, setCategoryTotalPages] = useState(0);
  const [productTotalPages, setProductTotalPages] = useState(0);
  const [count, setCount] = useState();

  const addToCart = (item) => {
    setCartItems([...cartItems, item]);
  };

  const fetchDataFromAPI = useCallback(async () => {
    const data = await fetchData(`/category/product?page=${currentPage}`);
    if (data) {
      setProductItems(data.products);
      setCategories(data.categories);
      setCategoryTotalPages(data.category_total_page_number);
      setProductTotalPages(data.product_total_page_number);
    }
  }, [currentPage]);

  useEffect(() => {
    fetchDataFromAPI();
  }, [fetchDataFromAPI]);

  const handleInputChange = useCallback((event) => {
    setSearchQuery(event.target.value);
  }, []);

  const handleRangeChange = (values) => {
    setMinPrice(values[0]);
    setMaxPrice(values[1]);
  };

  const handleCategoryChange = useCallback(
    (categoryName) => {
      if (selectedCategories.includes(categoryName)) {
        setSelectedCategories(
          selectedCategories?.filter((category) => category !== categoryName)
        );
      } else {
        setSelectedCategories([...selectedCategories, categoryName]);
      }
    },
    [selectedCategories]
  );

  const toggleFavorite = () => {
    setIsFavorite(!isFavorite);
  };

  const handlePageChange = useCallback((pageNumber) => {
    setCurrentPage(pageNumber);
  }, []);

  const filterProducts = useCallback(
    (products) => {
      const searchValue = searchQuery.toLowerCase();
      const filteredBySearch = products?.filter((product) =>
        product.name.toLowerCase().includes(searchValue)
      );

      const filteredByPriceRange = filteredBySearch?.filter(
        (product) => product.cost >= minPrice && product.cost <= maxPrice
      );

      if (selectedCategories.length === 0) {
        return filteredByPriceRange;
      } else {
        return filteredByPriceRange.filter((product) =>
          selectedCategories.includes(product.category.name)
        );
      }
    },
    [searchQuery, minPrice, maxPrice, selectedCategories]
  );

  const isItemInCart = (itemId) => {
    return cartItems.some((item) => item.id === itemId);
  };
  // post data
  const [isInCart, setIsItemInCart] = useState(false);

  const checkCartStatus = async () => {
    try {
      const response = await api.get("/takeorder");
      const cartItems = response.data.order;
      const isInCart = cartItems.some((item) => item.product);
      setIsItemInCart(isInCart);
    } catch (error) {
      console.error(error);
    }
  };

  const { changetest } = useContext(AuthContext);
  const handleAddToCart = async (item) => {
    try {
      const product = item;

      // Check if the item already exists in the cart
      if (isInCart) {
        console.log("Item already exists in the cart.");
        return;
      }

      const response = await api.post("/takeorder", {
        product: product.id,
        price: product.cost,
        quantity: 1,
      });
      if (response.status === 200) {
        console.log(response.data.message);
        if (response.data.message === "Already exist in the cart.") {
          return toast.error("Already exist in the cart.");
        }
        toast.success("Successfully Added");
        changetest();
        console.log("Item added to cart successfully.");
        // Add the item to the cart
        setCartItems((prevItems) => [...prevItems, product]);
      } else {
        console.log("Failed to add item to cart.");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const add = () => {
    setCount((prevCount) => prevCount + 1);
  };

  const sub = () => {
    if (count > 0) {
      setCount((prevCount) => prevCount - 1);
    }
  };

  return (
    <>
      <Helmet>
        <title>Our Shop | Presu's Skincare</title>
        <meta
          name="description"
          content="Discover a range of premium skincare products available at the Presu's Skincare online shop. Elevate your skincare routine."
        />
        <meta
          name="keywords"
          content="Skincare products, online shop, Presu's Skincare, premium skincare"
        />
      </Helmet>

      <div className="container mx-auto flex flex-col md:flex-row gap-12 mt-32 p-4">
        <div className="w-full md:w-1/4 bg-white p-4 rounded-lg shadow">
          <h1 className="text-2xl font-bold text-gray-800 mb-6">
            Filter Product
          </h1>
          <div className="mb-6">
            <div className="flex items-center mb-2">
              <input
                type="text"
                placeholder="Search products"
                value={searchQuery}
                onChange={handleInputChange}
                className="px-3 py-2 border border-gray-300 rounded w-full"
              />
            </div>
          </div>

          <div className="w-64">
            <h2 className="flex items-center text-lg font-semibold text-gray-800 mb-4">
              Categories
            </h2>
            <div className="mb-6">
              {categories?.map((category, index) => (
                <div
                  key={index}
                  className={`flex items-center mb-2 ${
                    selectedCategories.includes(category.name)
                      ? "bg-pink-100 text-pink-600"
                      : "bg-white text-gray-600"
                  } py-2 px-4 rounded cursor-pointer transition-colors duration-300 hover:bg-pink-50 hover:text-pink-500`}
                  onClick={() => handleCategoryChange(category.name)}
                >
                  <span className="mr-2">
                    {selectedCategories.includes(category.name) ? (
                      <FaChevronRight className="text-pink-600" />
                    ) : (
                      <FaChevronRight className="text-gray-500" />
                    )}
                  </span>
                  <label
                    htmlFor={`category-${index}`}
                    className="text-sm font-medium"
                  >
                    {category.name}
                  </label>
                </div>
              ))}
            </div>
          </div>
          <div className="mb-6">
            <h2 className="flex items-center text-lg font-semibold text-gray-800 mb-4">
              Price Range
            </h2>
            <div className="flex flex-col">
              <Range
                values={[minPrice, maxPrice]}
                min={0}
                max={10000}
                step={1}
                allowOverlap={false}
                onChange={handleRangeChange}
                renderTrack={({ props, children }) => (
                  <div
                    {...props}
                    className="h-2 bg-gradient-to-r from-indigo-500 to-pink-400 rounded-sm mt-2 mb-2"
                  >
                    {children}
                  </div>
                )}
                renderThumb={({ props }) => (
                  <div
                    {...props}
                    className="w-4 h-4 bg-pink-500 rounded-full shadow"
                  />
                )}
              />
              <div className="flex justify-between text-sm text-gray-700">
                <span>Rs. {minPrice}</span>
                <span>Rs. {maxPrice}</span>
              </div>
            </div>
          </div>
        </div>
        <div className="w-full md:w-3/4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          {filterProducts(productItems)?.map((product) => (
            <div
              key={product.id}
              className="bg-white rounded-lg border p-4 transition-all duration-300 hover:shadow-lg relative"
              onMouseEnter={() => setHoveredItem(product.id)}
              onMouseLeave={() => setHoveredItem(null)}
            >
              {/* Discount Label */}
              {product.discount !== undefined && product.discount !== 0 && (
                <div className="absolute top-2 left-1 bg-red-100 text-red-500 font-semibold rounded-md px-2 py-1">
                  {product.discount}% off
                </div>
              )}

              {/* Product Image */}
              <img
                src={`${BASE_URL}${product.image}`}
                alt={product.title}
                className="w-full h-48 object-cover mb-4"
              />

              {/* Product Information */}
              <div className="flex justify-between items-center pb-2">
                <p className="bg-pink-100 px-1 text-pink-500 rounded">
                  {product.category.name}
                </p>
                <p className="text-gray-600">Rs. {product.cost}</p>
              </div>

              <Link to={`/product/${product.id}`}>
                <p className="font-bold text-gray-800">{product.name}</p>
              </Link>

              <div className="flex justify-between items-center">
                <div className="flex items-center border border-yellow-500 rounded px-1 mt-2">
                  <p className="text-yellow-500">5.0</p>
                  <FaStar className="text-yellow-500 ml-1" />
                </div>
                <button
                  className={`px-4 py-2 rounded-full text-white bg-pink-500 hover:bg-pink-600 `}
                  onClick={() => handleAddToCart(product)}
                  disabled={isItemInCart(product.id)}
                >
                  <FaShoppingCart />
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="container mx-auto mt-8 py-4">
        <nav className="flex justify-center">
          {Array.from({ length: productTotalPages }, (_, i) => i + 1).map(
            (pageNumber) => (
              <button
                key={pageNumber}
                className={`px-4 py-2 mx-1 rounded ${
                  pageNumber === currentPage
                    ? "bg-pink-200 text-pink-600"
                    : "bg-gray-100 text-gray-700"
                }`}
                onClick={() => handlePageChange(pageNumber)}
              >
                {pageNumber}
              </button>
            )
          )}
        </nav>
      </div>
      <ToastContainer autoClose={2000} />
      <Shopping cartItems={cartItems} />
    </>
  );
};

export default Product;
