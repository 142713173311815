import {React,useState, useEffect} from "react";
import { FaPlus, FaStar } from "react-icons/fa";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import axios from "axios";
import { Link } from "react-router-dom";
// import backgroundImage from "../../assets/pink.svg"
import url from "../../utils/useAxios";
import { BASE_URL } from "../../context/AuthContext";
const api = url()

const Services = () => {
  // there is not array defined for datas before
  // const bgImg = `url(${backgroundImage})`;
  const [datas, setDatas] = useState([]);
  const getData = async () => {
    const res = await api.get(`/service/?page=1`);
    setDatas(res.data.service);
    console.log(res.data.service);
  };
  useEffect(() => {
    getData();
    console.log("aaa");
  }, []);

 

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 5,
      slidesToSlide: 1,
    },
    tablet: {
      breakpoint: { max: 1023, min: 768 },
      items: 2,
      slidesToSlide: 2,
    },
    mobile: {
      breakpoint: { max: 767, min: 0 },
      items: 1,
      slidesToSlide: 1,
    },
  };

  return (
    <div className="py-10 -z-50">
      <div className="flex flex-col items-center justify-center mb-2">
        <div className="flex justify-center font-bold font-dancing text-4xl text-[#DD0786] px-4">
          Our Services
        </div>
        <div className="flex justify-center items-center w-28">
          <div className="flex-grow bg-gradient-to-r from-purple-500 to-pink-500 rounded-full w-8 h-1"></div>
          <FaStar className="mx-2 text-pink-500" />
          <div className="flex-grow bg-gradient-to-r from-pink-500 to-purple-500 rounded-full w-8 h-1"></div>
        </div>
      </div>  
      <Carousel
        responsive={responsive}
        infiniteLoop
        autoPlay
        autoPlaySpeed={2000}
        arrows={true}
        renderButtonGroupOutside
      >
        {datas?.map((service) => (
              <div className='flex flex-col items-center p-2 cursor-pointer duration-300 hover:-translate-y-1 '>
              <div className="flex items-center justify-center w-40 h-40 transform rotate-90">
                <div
                  className="w-32 h-32 relative bg-gradient-to-r from-pink-500 via-pink-400 to-pink-300 pb-3 pt-3"
                  style={{
                    borderRadius: '50%',
                    overflow: 'hidden',
                    position: 'relative',
                    zIndex: 1,
                  }}
                >
                  <img
                    src={`${BASE_URL}${service.image}`}
                    alt="Circle Image"
                    className="rotate-img w-full h-full "
                    style={{
                      objectPosition: 'center center',
                      borderRadius: '50%',
                    }}
                  />
                </div>
                <div
                  className="w-12 h-12 transform rotate-45 -translate-x-8 bg-pink-300"
                ></div>
              </div>
              <Link to={`/services/${service.id}`}>
              <div className="flex justify-start -translate-y-4  w-fit px-4 py-1 text-white rounded-sm font-semibold bg-gradient-to-t to-pink-400 from-pink-500">{service.name}</div>
              </Link>

              </div>
        ))}
      </Carousel>

    </div>

    
  );
};

export default Services;