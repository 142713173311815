import React, { useState, useEffect } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { FaStar } from "react-icons/fa";
import Appointment from "./Appointment";
import axios from "axios";
import backgroundImage from "../../assets/5.png";
import aboutImage from "../../assets/aboutimg.png";
import url from "../../utils/useAxios";
const api = url();

const Ourservices = () => {
  const bgImg = `url(${backgroundImage})`;
  const [currentPage, setCurrentPage] = useState(1);

  const [datas, setDatas] = useState([]);
  const getData = async (page) => {
    const res = await api.get(`/service?page=${page}`);
    setDatas(res.data.service);
    console.log(res.data);
  };
  useEffect(() => {
    getData(currentPage);
    console.log("ehkj");
  }, [currentPage]);

  return (
    <>
      <div
        className="flex flex-col p-4"
        style={{ backgroundImage: `${bgImg}` }}
      >
        <div className="flex lg:flex-row flex-col lg:gap-8 sm:gap-10 gap-12">
          <div className="w-full lg:w-6/12">
            <h2 className="w-full font-bold lg:text-4xl text-4xl  font-sriracha text-[#DD0786] lg:leading-10 leading-9">
              Dermatologist and skin specialist in Nepal
            </h2>
            <p className="font-normal text-base leading-6 text-justify text-gray-600 mt-6">
              Presu Skin Care is a premium skincare brand dedicated to providing
              you with high-quality, natural products that nourish and
              rejuvenate your skin. Our expert team formulates effective, safe
              products tailored to address specific skincare concerns. We
              prioritize natural ingredients, transparency, and integrity in our
              approach. Experience the transformative effects of Presu Skin Care
              and discover the power of nature for healthier, radiant skin.
              <br />
              <br />
              Choose Presu Skin Care and embrace the power of nature for a
              healthier, more vibrant you. Your skin deserves the best, and we
              are committed to providing it. Discover the transformative effects
              of our skincare products and embark on a journey to radiant,
              nourished skin today. Choose Presu Skin Care and embrace the power
              of nature for a healthier, more vibrant you. Your skin deserves
              the best, and we are committed to providing it. Discover the
              transformative effects of our skincare products and embark on a
              journey to radiant, nourished skin today.
            </p>
          </div>
          <div className="w-full lg:w-6/12">
            <img
              className="lg:block hidden w-full rounded-md"
              src={aboutImage}
              alt="hydrafacial"
            />
            <img
              className="lg:hidden sm:block hidden w-full rounded-md"
              src={aboutImage}
              alt="hydrafacial"
            />
            <img
              className="sm:hidden block w-full rounded-md"
              src={aboutImage}
              alt="hydrafacial"
            />
          </div>
        </div>
        <div className="flex flex-col lg:flex-row justify-center mt-4 gap-6">
          <div className="w-full lg:w-6/12">
            <div className="flex justify-start font-bold text-2xl text-[#DD0786] mb-4">
              Our Services
            </div>
            {datas?.map((items) => (
              <div className="flex flex-col">
                <h1 className="flex justify-start text-slate-700 font-sriracha font-semibold text-[18px] py-1">
                  {items.name}
                </h1>
                <p className="text-[14px] text-justify text-gray-600">
                  {items.description}
                </p>
              </div>
            ))}
          </div>
          <div className="w-full lg:w-6/12">
            <Appointment />
          </div>
        </div>
      </div>
    </>
  );
};

export default Ourservices;
