import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import placeholderImage from "../../assets/gallery3.png";
import url from "../../utils/useAxios";
import { FaStar, FaTimes } from "react-icons/fa";
import { BASE_URL } from "../../context/AuthContext";
const api = url();

const Gallery = () => {
  const [galleryItems, setGalleryItems] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const getData = async () => {
    try {
      const res = await api.get(`/gallery`);
      console.log(res.data); // Check the response data
      setGalleryItems(res.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getData();
    console.log("gallery");
  }, []);

  const openModal = (image) => {
    setSelectedImage(image);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setSelectedImage(null);
    setIsModalOpen(false);
  };

  const getRandomHeight = () => {
    return Math.floor(Math.random() * (300 - 200 + 1) + 200); // Adjust the range as per your needs
  };

  return (
    <div className="px-4 p-10">
      <div className="flex flex-col items-center justify-center mb-2">
        <div className="flex justify-center font-bold font-dancing text-4xl text-[#DD0786] px-4">
          Our Portfolio
        </div>
        <div className="flex justify-center items-center w-28">
          <div className="flex-grow bg-gradient-to-r from-purple-500 to-pink-500 rounded-full w-8 h-1"></div>
          <FaStar className="mx-2 text-pink-500" />
          <div className="flex-grow bg-gradient-to-r from-pink-500 to-purple-500 rounded-full w-8 h-1"></div>
        </div>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
        {galleryItems.map((item) => (
          <div
            key={item.id}
            className="bg-white rounded-lg overflow-hidden shadow-md"
            style={{ height: `${getRandomHeight()}px` }}
          >
            <div className="relative">
              <img
                src={`${BASE_URL}${item.image}`}
                alt="Gallery Image"
                className="w-full h-full object-cover"
              />
              <div className="absolute top-0 left-0 right-0 bottom-0 bg-gray-800 bg-opacity-30 opacity-0 hover:opacity-100 transition-opacity duration-300">
                <div className="flex justify-center items-center h-full">
                  <button
                    onClick={() => openModal(item)}
                    className="text-white font-bold text-lg"
                  >
                    {item.title}
                  </button>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>

      {isModalOpen && selectedImage && (
          <div className="fixed top-0 left-0 right-0 bottom-0 flex items-center justify-center gallery-modal bg-gray-900 bg-opacity-50">
            <div className="max-w-screen-lg h-[70vh]   flex flex-col items-end justify-center">
              <button
                onClick={closeModal}
                className="mt-1 px-2 py-2 items-end text-white bg-pink-500 border border-pink-500 translate-y-[36px] -translate-x-[2px] rounded-full focus:outline-none hover:bg-pink-600  hover:border-pink-500 "
              >
                <FaTimes />
              </button>
              <img
                src={`${BASE_URL}${selectedImage.image}`}
                alt={selectedImage.alt}
                className="max-w-full max-h-[70vh] rounded-md shadow-md"
              />
            </div>
          </div>
        )}
    </div>
  );
};

export default Gallery;
