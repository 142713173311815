import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { FaHeart } from "react-icons/fa";
import axios from "axios";
import Shopping from "../component/Shopping";
import { useContext } from "react";
import AuthContext, { BASE_URL } from "../../context/AuthContext";
import { ToastContainer, toast } from "react-toastify";
import url from "../../utils/useAxios";
import { Helmet } from "react-helmet";
const api = url();

const useLocalStorageState = (key, defaultValue) => {
  const [state, setState] = useState(() => {
    const storedValue = localStorage.getItem(key);
    return storedValue ? JSON.parse(storedValue) : defaultValue;
  });

  useEffect(() => {
    localStorage.setItem(key, JSON.stringify(state));
  }, [key, state]);

  return [state, setState];
};

const ProductDetail = () => {
  const params = useParams();
  const [productItems, setProductItems] = useState([]);
  const [count, setCount] = useState(0);

  const getData = async () => {
    try {
      const res = await api.get(`/category/product?page=1`);
      setProductItems(res.data.products.filter((item) => item.id == params.id));
      console.log(res.data.products);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getData();
    console.log("productdetails");
  }, []);

  const [isFavorite, setIsFavorite] = useLocalStorageState("isFavorite", false);
  const toggleFavorite = () => {
    setIsFavorite(!isFavorite);
  };

  const [cartItems, setCartItems] = useState([]);
  const [isInCart, setIsInCart] = useState(false);

  const checkCartStatus = async () => {
    try {
      const response = await api.get("/takeorder");
      const cartItems = response.data.order;
      const isInCart = cartItems.some(
        (item) => item.product === productItems[0].id
      );
      setIsInCart(isInCart);
    } catch (error) {
      console.error(error);
    }
  };

  const { changetest } = useContext(AuthContext);
  const handleAddToCart = async () => {
    try {
      const product = productItems[0];

      // Check if the item already exists in the cart
      if (isInCart) {
        console.log("Item already exists in the cart.");
        return;
      }

      const response = await api.post("/takeorder", {
        product: product.id,
        price: product.cost,
        quantity: 1,
      });
      if (response.status === 200) {
        console.log(response.data.message);
        if (response.data.message === "Already exist in the cart.") {
          return toast.error("Already exist in the cart.");
        }
        toast.success("Successfully Added");
        changetest();
        console.log("Item added to cart successfully.");
        // Add the item to the cart
        setCartItems((prevItems) => [...prevItems, product]);
      } else {
        console.log("Failed to add item to cart.");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const [randomNumber, setRandomNumber] = useLocalStorageState(
    "randomNumber",
    null
  );

  useEffect(() => {
    if (!randomNumber) {
      const newRandomNumber = Math.floor(Math.random() * 50) + 1; // Generates a random number between 1 and 50
      setRandomNumber(newRandomNumber);
    }
  }, [randomNumber, setRandomNumber]);

  useEffect(() => {
    checkCartStatus();
  }, [productItems]);

  return (
    <>
      <Helmet>
        <title>Our Shop| Presu's Skincare</title>
        <meta
          name="description"
          content="We provide painless treatment using various procedures. We deal with a wide range of skin issues."
        />
        <meta name="description" content="Best Skincare Center in Chitwan." />
      </Helmet>
      <section className="text-gray-700 body-font overflow-hidden  bg-white mt-32">
        <div className="container px-5 py-24 mx-auto">
          <div className="lg:w-4/5 mx-auto flex flex-wrap">
            {productItems.map((item) => (
              <img
                key={item.id}
                alt="ecommerce"
                className="lg:w-1/2 w-full object-cover object-center rounded border border-gray-200"
                src={`${BASE_URL}${item.image}`}
                // src={item.image}
              />
            ))}
            <div className="lg:w-1/2 w-full lg:pl-10 lg:py-6 mt-6 lg:mt-0">
              {productItems.map((item) => (
                <h2
                  key={item.id}
                  className="text-sm title-font text-gray-500 tracking-widest"
                >
                  {item.brand}
                </h2>
              ))}
              {productItems.map((item) => (
                <h1
                  key={item.id}
                  className="text-gray-900 text-3xl title-font font-medium mb-1"
                >
                  {item.name}
                </h1>
              ))}
              <div className="flex mb-4">
                <span className="flex items-center">
                  <svg
                    fill="currentColor"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    className="w-4 h-4 text-red-500"
                    viewBox="0 0 24 24"
                  >
                    <path d="M12 2l3.09 6.26L22 9.27l-5 4.87 1.18 6.88L12 17.77l-6.18 3.25L7 14.14 2 9.27l6.91-1.01L12 2z" />
                  </svg>
                  <svg
                    fill="currentColor"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    className="w-4 h-4 text-red-500"
                    viewBox="0 0 24 24"
                  >
                    <path d="M12 2l3.09 6.26L22 9.27l-5 4.87 1.18 6.88L12 17.77l-6.18 3.25L7 14.14 2 9.27l6.91-1.01L12 2z" />
                  </svg>
                  <svg
                    fill="currentColor"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    className="w-4 h-4 text-red-500"
                    viewBox="0 0 24 24"
                  >
                    <path d="M12 2l3.09 6.26L22 9.27l-5 4.87 1.18 6.88L12 17.77l-6.18 3.25L7 14.14 2 9.27l6.91-1.01L12 2z" />
                  </svg>
                  <svg
                    fill="currentColor"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    className="w-4 h-4 text-red-500"
                    viewBox="0 0 24 24"
                  >
                    <path d="M12 2l3.09 6.26L22 9.27l-5 4.87 1.18 6.88L12 17.77l-6.18 3.25L7 14.14 2 9.27l6.91-1.01L12 2z" />
                  </svg>
                  <svg
                    fill="url(#halfGradient)"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    className="w-4 h-4 text-red-500"
                    viewBox="0 0 24 24"
                  >
                    <defs>
                      <linearGradient
                        id="halfGradient"
                        x1="0%"
                        y1="0%"
                        x2="100%"
                        y2="0%"
                      >
                        <stop
                          offset="50%"
                          stopColor="currentColor"
                          stopOpacity="1"
                        />
                        <stop
                          offset="50%"
                          stopColor="transparent"
                          stopOpacity="0"
                        />
                      </linearGradient>
                    </defs>
                    <path d="M12 2l3.09 6.26L22 9.27l-5 4.87 1.18 6.88L12 17.77l-6.18 3.25L7 14.14 2 9.27l6.91-1.01L12 2z" />
                  </svg>

                  <span className="text-gray-600 ml-3">
                    {randomNumber && <p>{randomNumber} reviews</p>}
                  </span>
                </span>
              </div>
              {productItems.map((item) => (
                <p key={item.id} className="leading-relaxed">
                  {item.description}
                </p>
              ))}
              <div className="flex mt-6 items-center pb-5 border-b-2 border-gray-200 mb-5"></div>
              <div className="flex gap-4 items-center">
                {productItems.map((item) => (
                  <span
                    key={item.id}
                    className="title-font font-medium text-2xl text-gray-900 "
                  >
                    Rs. {item.cost}
                  </span>
                ))}
                {isInCart ? (
                  <span className="text-sm text-green-500">
                    Already in Cart
                  </span>
                ) : (
                  <button
                    onClick={handleAddToCart}
                    className="flex ml-auto text-white bg-pink-500 border-0 py-2 px-6 focus:outline-none hover:bg-pink-600 rounded"
                  >
                    Add to Cart
                  </button>
                )}
                <button
                  onClick={toggleFavorite}
                  className="text-gray-500 bg-gray-200 rounded-full w-10 h-10  focus:outline-none mr-3 flex justify-center items-center"
                >
                  {isFavorite ? (
                    <FaHeart className="text-pink-500" />
                  ) : (
                    <FaHeart />
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer />
        <Shopping cartItems={cartItems} />
      </section>
    </>
  );
};

export default ProductDetail;
