const { createContext, useState } = require("react");

const AuthContext = createContext();

export default AuthContext;
export const BASE_URL = "https://server.presuskincare.com"
export const AuthProvider = ({ children }) => {
  const [test, setTest] = useState(false);

  const changetest = () => {
    setTest(!test);
  };

  const addtest = () => {
    setTest(!test);
  };

  const removetest = () => {
    setTest(!test);
  };
  
  let contextData = {
    test: test,
    changetest: changetest,
    addtest: addtest,
    removetest: removetest,
  };

  return (
    <AuthContext.Provider value={contextData}>
      {children}
    </AuthContext.Provider>
  );
};
