import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { FaShoppingCart, FaStar } from "react-icons/fa";
import { ToastContainer, toast } from "react-toastify";
import AuthContext, { BASE_URL } from "../../context/AuthContext";
import backgroundImage from "../../assets/bg-team-details.png";
import url from "../../utils/useAxios";
const api = url();

const Ourproduct = (props) => {
  const bgImg = `url(${backgroundImage})`;
  const [data, setData] = useState([]);
  const getData = async () => {
    try {
      const res = await api.get(`/category/product?page=1`);
      setData(res.data.products);
      console.log(res.data.products);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getData();
    console.log("modaldtl");
  }, []);

  const [isFavorite, setIsFavorite] = useState(false);

  const toggleFavorite = () => {
    setIsFavorite(!isFavorite);
  };

  const [cartItems, setCartItems] = useState([]);
  const [isInCart, setIsItemInCart] = useState(false);

  const checkCartStatus = async () => {
    try {
      const response = await api.get("/takeorder");
      const cartItems = response.data.order;
      const isInCart = cartItems.some((item) => item.product);
      setIsItemInCart(isInCart);
    } catch (error) {
      console.error(error);
    }
  };

  const { changetest } = useContext(AuthContext);
  const handleAddToCart = async (item) => {
    try {
      const product = item;

      // Check if the item already exists in the cart
      if (isInCart) {
        console.log("Item already exists in the cart.");
        return;
      }

      const response = await api.post("/takeorder", {
        product: product.id,
        price: product.cost,
        quantity: 1,
      });
      if (response.status === 200) {
        console.log(response.data.message);
        if (response.data.message === "Already exist in the cart.") {
          return toast.error("Already exist in the cart.");
        }
        toast.success("Successfully Added");
        changetest();
        console.log("Item added to cart successfully.");
        // Add the item to the cart
        setCartItems((prevItems) => [...prevItems, product]);
      } else {
        console.log("Failed to add item to cart.");
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <div className=" flex flex-col justify-between py-10  -z-50 ">
        <div className="flex flex-col items-center justify-center mb-2">
          <div className="flex justify-center font-bold font-dancing text-4xl text-[#DD0786] px-4">
            Our Products
          </div>
          <div className="flex justify-center items-center w-28">
            <div className="flex-grow bg-gradient-to-r from-purple-500 to-pink-500 rounded-full w-8 h-1"></div>
            <FaStar className="mx-2 text-pink-500" />
            <div className="flex-grow bg-gradient-to-r from-pink-500 to-purple-500 rounded-full w-8 h-1"></div>
          </div>
        </div>
        <div className="w-full p-4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
          {data.map((product) => (
            <div
              key={product.id}
              className="bg-white rounded-lg  p-4 transition-all  duration-300 hover:-translate-y-1 border hover:shadow-lg relative"
            >
              {/* Discount Label */}
              {product.discount !== undefined && product.discount !== 0 && (
                <div className="absolute top-2 left-1 bg-red-100 text-red-500 font-semibold rounded-md px-2 py-1">
                  {product.discount}% off
                </div>
              )}

              {/* Product Image */}
              <img
                src={`${BASE_URL}${product.image}`}
                alt={product.title}
                className="w-full h-48 object-cover mb-4 rounded"
              />

              {/* Product Information */}
              <div className="flex justify-between items-center pb-2">
                <p className="bg-pink-100 px-1 text-pink-500 rounded">
                  {product.category.name}
                </p>
                <p className="text-gray-600">Rs. {product.cost}</p>
              </div>

              <Link to={`/product/${product.id}`}>
                <p className="font-bold text-gray-800">{product.name}</p>
              </Link>

              <div className="flex justify-between items-center">
                <div className="flex items-center border border-yellow-500 rounded px-1 mt-2">
                  <p className="text-yellow-500">5.0</p>
                  <FaStar className="text-yellow-500 ml-1" />
                </div>
                <button
                  className={`px-4 py-2 rounded-full text-white bg-pink-500 hover:bg-pink-600 `}
                  onClick={() => handleAddToCart(product)}
                >
                  <FaShoppingCart />
                </button>
              </div>
            </div>
          ))}
        </div>
        <Link to={"product"}>
          <div className="flex justify-center py-4">
            <h6 className="text-lg mb-1 bg-gradient-to-b from-pink-400 to-pink-600 w-fit p-2 px-6 rounded-full text-white  ">
              Show All
            </h6>
          </div>
        </Link>
      </div>
      <ToastContainer />
    </>
  );
};

export default Ourproduct;
