import React, { useState, useEffect } from "react";
import axios from "axios";
import { useLocation, useParams } from "react-router-dom";
import image from "../../../src/assets/blog3.png";
import Shopping from "../component/Shopping";
import Blog from "./Blog";
import url from "../../utils/useAxios";
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
} from "react-share";
import { FaFacebook, FaTwitter, FaLinkedin } from "react-icons/fa";
import { BASE_URL } from "../../context/AuthContext";
const api = url();

const SingleBlog = () => {
  const location = useLocation();
  console.log("Current URL:", window.location.href);

  const params = useParams();
  const [blogitems, setBlogitems] = useState([]);
  const getData = async () => {
    const res = await api.get(`/blog?page=1`);
    setBlogitems(res.data.filter((item) => item.id == params.id));
    console.log(res.data);
  };
  useEffect(() => {
    getData();
    console.log("blogsss");
  }, []);
  return (
    <>
      <div className="flex flex-col px-4 sm:px-20  items-center mt-36">
        {blogitems?.map((dat) => (
          <div className="flex w-full justify-start text-3xl hover:text-pink-500 cursor-pointer font-semibold">
            {" "}
            {dat.title}
          </div>
        ))}
        <div className="flex w-full justify-start mt-4">
          {blogitems && blogitems.length > 0 && (
            <>
              <FacebookShareButton
                url={`${window.location.origin}${location.pathname}`}
                quote={blogitems[0]?.title}
              >
                <FaFacebook className="text-2xl text-blue-500 mx-2 cursor-pointer" />
              </FacebookShareButton>

              <TwitterShareButton
                url={`${window.location.origin}${location.pathname}`}
                title={blogitems[0]?.title}
              >
                <FaTwitter className="text-2xl mx-2 text-sky-500 cursor-pointer" />
              </TwitterShareButton>

              <LinkedinShareButton
                url={`${window.location.origin}${location.pathname}`}
                title={blogitems[0]?.title}
              >
                <FaLinkedin className="text-2xl mx-2 text-sky-700 cursor-pointer" />
              </LinkedinShareButton>
            </>
          )}
        </div>
        <div className="flex w-full h-[80vh] mt-3  ">
          {blogitems?.map((dat) => (
            <img
              src={`${BASE_URL}${dat?.image}`}
              className="h-full object-cover w-full rounded-md "
              alt=""
            />
          ))}
        </div>
        <div className="max-w-4xl mx-auto">
          {blogitems?.map((item) => (
            <div
              key={item.id}
              className="text-gray-600 text-justify mt-4  mb-4"
              dangerouslySetInnerHTML={{ __html: item.description }}
            ></div>
          ))}
        </div>
      </div>
      <Blog />
      <Shopping />
    </>
  );
};

export default SingleBlog;
