import React, { useState } from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import backgroundImage1 from "../../assets/hydrafacial2.jpeg";
import backgroundImage4 from "../../assets/hydrafacial3.webp";
import backgroundImage2 from "../../assets/main2.jpeg";
// import backgroundImage3 from "../../assets/main3.avif";

function HeroSection() {
  const slides = [
    {
      backgroundImage: backgroundImage1,
      title: "Boost Your Skin. Boost Your Confidence",
      desc: "Experience the transformation as our skincare solutions enhance your natural beauty, boost your confidence, and leave you with glowing, radiant skin that you'll love to flaunt.",
      buttonText: "Why Us?",
      buttonLink: "/about",
      buttonText1: "Contact",
      buttonLink1: "/contact",
    },
    {
      backgroundImage: backgroundImage2,
      title: "Discover Our Skincare Solutions.",
      desc: "Explore a world of skincare excellence with our carefully crafted solutions. From personalized routines to premium products, we're your trusted partner on the journey to healthier, more radiant skin.",
      buttonText: "Shop Now",
      buttonLink: "/product",
      buttonText1: "Appointment",
      buttonLink1: "/appointment",
    },
    {
      backgroundImage: backgroundImage4,
      title: "Get in Touch with Us.",
      desc: "We're here to help you on your skincare journey. Whether you have questions, need advice, or want to book a consultation, don't hesitate to get in touch with our team of skincare experts. Your skin deserves the best, and we're here to provide it.",
      buttonText: "Services",
      buttonLink: "/service",
      buttonText1: "Learn More",
      buttonLink1: "/about",
    },
  ];

  const settings = {
    dots: false,
    infinite: true,
    speed: 3000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
  };

  return (
    <Slider {...settings}>
      {slides.map((slide, index) => (
        <div
          key={index}
          className={`w-screen h-[80vh] mt-18 sm:mt-18 md:mt-16 lg:mt-16 -z-50`}
        >
          <div
            className="bg-cover bg-center h-full"
            style={{ backgroundImage: `url(${slide.backgroundImage})` }}
          >
            <motion.div
              className="container mx-auto flex px-5 py-24 items-center justify-center flex-col h-full"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.5 }}
            >
              <div className="text-center w-full">
                <h1 className="my-4 sm:text-6xl flex item-center justify-center bg-gradient-to-bl from-purple-600 via-rose-500 to-rose-700 bg-clip-text text-transparent font-courgette text-3xl font-bold leading-tight">
                  {slide.title}
                </h1>
                {/* <p className="text-sm  sm:text-lg md:text-normal   text-gray-600  font-semibold font-serif mb-4">
                  "{slide.desc}"
                </p> */}
                <div className="flex justify-center mx-auto gap-2">
                  <motion.button
                    className="bg-white text-pink-600 font-bold rounded-full py-4 px-8 hover:shadow-md"
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                  >
                    <Link to={slide.buttonLink}>{slide.buttonText}</Link>
                  </motion.button>
                  <motion.button
                    className="bg-white text-pink-600 font-bold  rounded-full py-4 px-8 hover:shadow-md"
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                  >
                    <Link to={slide.buttonLink1}>{slide.buttonText1}</Link>
                  </motion.button>
                </div>
              </div>
            </motion.div>
          </div>
        </div>
      ))}
    </Slider>
  );
}

export default HeroSection;
