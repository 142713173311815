import { React, useState, useEffect } from "react";
import circle from "../../assets/circle.png";
import Shopping from "../component/Shopping";
import url from "../../utils/useAxios";
import { BASE_URL } from "../../context/AuthContext";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { FaStar } from "react-icons/fa";
const api = url();

const Services = () => {
  const [serviceItems, setServiceItems] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);

  const getData = async (page) => {
    const res = await api.get(`/service/?page=${page}`);
    setServiceItems(res.data.service);
    console.log(res.data.service);
  };

  useEffect(() => {
    getData(currentPage);
    console.log("servicessss");
  }, [currentPage]);

  return (
    <>
      <Helmet>
        <title>Our Services | Presu's Skincare</title>
        <meta
          name="description"
          content="Explore a wide range of skincare services offered by Presu's Skincare in Chitwan. We provide personalized treatments for your skin."
        />
        <meta
          name="keywords"
          content="Skincare services, Presu's Skincare, Chitwan, personalized skincare"
        />
      </Helmet>

      <div className=" flex flex-col justify-between p-4 py-10 sm:mt-28 mt-16 sm:mb-14 mb-20">
        <div className="flex flex-col items-center justify-center mb-20 sm:mb-20">
          <div className="flex justify-center font-bold font-dancing text-4xl text-[#DD0786] px-4">
            Our Services
          </div>
          <div className="flex justify-center items-center w-28">
            <div className="flex-grow bg-gradient-to-r from-purple-500 to-pink-500 rounded-full w-8 h-1"></div>
            <FaStar className="mx-2 text-pink-500" />
            <div className="flex-grow bg-gradient-to-r from-pink-500 to-purple-500 rounded-full w-8 h-1"></div>
          </div>
        </div>

        <div className="flex flex-col justify-between   rounded"></div>
        <div className="grid grid-cols-1  md:grid-cols-3 lg:grid-cols-5  gap-4 ">
          {serviceItems?.map((item, index) => (
            <div className="bg-slate-50  shadow rounded-lg sm:mb-14 mb-20 hover:-translate-y-2 transition-all duration-300">
              <Link to={`/services/${item.id}`}>
                <div className="flex flex-col items-center">
                  <img
                    src={circle}
                    alt="Background"
                    className="w-24 h-24 -mt-16"
                  />
                  <h1 className="text-pink-600 text-[50px] font-dancing -mt-[84px]">
                    {index + 1}
                  </h1>
                </div>

                <div className="text-center mt-6">
                  <h1 className="text-pink-600 text-2xl font-semibold font-sriracha mb-2">
                    {item.name}
                  </h1>
                  <p className="text-gray-500 text-justify px-4 font-urban" dangerouslySetInnerHTML={{ __html: item.description.slice(0, 170) }}>
                  </p>
                </div>

                <div className="mt-4">
                  <img
                    src={`${BASE_URL}${item.image}`}
                    className="w-full rounded-lg"
                    alt="Service"
                  />
                </div>
              </Link>
            </div>
          ))}
        </div>
      </div>
      <Shopping />
    </>
  );
};

export default Services;
