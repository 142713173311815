import React, { useState } from "react";
import {
  FaCartPlus,
  FaMapMarkerAlt,
  FaPhone,
  FaPhoneAlt,
  FaPhoneVolume,
} from "react-icons/fa";
import { NavLink } from "react-router-dom";
import image from "../assets/logo.png";

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <>
      <div className="flex flex-row justify-between bg-pink-200 fixed w-full top-0 header">
        <div className="hidden md:flex flex-col w-[33%]">
          <div className="py-2 px-16 flex tems-center gap-4">
            <h1 className="flex justify-start items-center gap-2 text-pink-700 font-semibold  py-1 ">
              <FaPhoneAlt /> 984-5243034
            </h1>
            <h1 className="flex justify-start items-center gap-2 text-pink-700 font-semibold  py-1 ">
              <FaMapMarkerAlt /> Sahidchowk, Chitwan
            </h1>
          </div>
          <div className="bg-[#E64678] h-[2px] w-full"></div>
          <div className="flex py-4 px-8 lg:px-16">
            <ul className="flex space-x-8 lg:space-x-16 text-[#C20475]">
              <NavLink
                exact
                to="/"
                activeClassName="active"
                className="navbar-link"
              >
                <li className="border-b-2 border-transparent font-semibold font-urban cursor-pointer hover:text-pink-900  transition duration-1000">
                  Home
                </li>
              </NavLink>
              <NavLink
                to="/about"
                activeClassName="active"
                className="navbar-link"
              >
                <li className="border-b-2 border-transparent font-semibold font-urban cursor-pointer hover:text-pink-900  transition duration-1000">
                  About
                </li>
              </NavLink>
              <NavLink
                to="/services"
                activeClassName="active"
                className="navbar-link"
              >
                <li className="border-b-2 border-transparent font-semibold font-urban cursor-pointer hover:text-pink-900  transition duration-1000">
                  Services
                </li>
              </NavLink>
            </ul>
          </div>
        </div>
        <div className="flex items-center justify-center w-[33%] p-[6px]">
          <img src={image} className="h-16 md:h-24 w-fit" alt="" />
        </div>
        <div className="md:hidden flex items-center">
          <button
            onClick={toggleMenu}
            className="text-2xl px-4 py-2 focus:outline-none"
          >
            {!isMenuOpen ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M4 6h16M4 12h16m-7 6h7"
                />
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            )}
          </button>
        </div>
        {isMenuOpen && (
          <div className="md:hidden fixed inset-0 header bg-gray-900 bg-opacity-50">
            <div className="bg-white w-1/2 h-screen fixed right-0 top-0 transform translate-x-0 transition-transform ease-in-out duration-300">
              <ul className="flex flex-col space-y-4 px-4 pt-4 items-center justify-center">
                <button
                  onClick={toggleMenu}
                  className="text-2xl px-4 py-2 focus:outline-none"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
                <NavLink
                  exact
                  to="/"
                  activeClassName="active"
                  className="navbar-link"
                  onClick={toggleMenu}
                >
                  <li>Home</li>
                </NavLink>
                <NavLink
                  to="/about"
                  activeClassName="active"
                  className="navbar-link"
                  onClick={toggleMenu}
                >
                  <li>About</li>
                </NavLink>
                <NavLink
                  to="/services"
                  activeClassName="active"
                  className="navbar-link"
                  onClick={toggleMenu}
                >
                  <li>Services</li>
                </NavLink>
                <NavLink
                  to="/blog"
                  activeClassName="active"
                  className="navbar-link"
                  onClick={toggleMenu}
                >
                  <li>Blog</li>
                </NavLink>
                <NavLink
                  to="/gallery"
                  activeClassName="active"
                  className="navbar-link"
                  onClick={toggleMenu}
                >
                  <li>Gallery</li>
                </NavLink>
                <NavLink
                  to="/product"
                  activeClassName="active"
                  className="navbar-link"
                  onClick={toggleMenu}
                >
                  <li>Shop</li>
                </NavLink>
                <NavLink
                  to="/appointment"
                  activeClassName="active"
                  className="navbar-link"
                  onClick={toggleMenu}
                >
                  <li>Appointment</li>
                </NavLink>
                <NavLink
                  to="/contact"
                  activeClassName="active"
                  className="navbar-link"
                  onClick={toggleMenu}
                >
                  <li>Contact</li>
                </NavLink>
              </ul>
            </div>
          </div>
        )}

        <div className="hidden md:flex flex-col w-[33%]">
          <div className="flex py-2 px-16 items-center gap-4 lg:gap-6 justify-end">
            <NavLink to="product">
              <button className="bg-white px-3 py-1 rounded flex items-center shadow-sm gap-3 text-[#C20475] font-semibold">
                <FaCartPlus /> Shop
              </button>
            </NavLink>
            <NavLink to="appointment">
              <button className="bg-gradient-to-b from-[#ffffff] to-[#ffc5dc] px-3 py-1 text-pink-700 rounded font-semibold">
                Appointment
              </button>
            </NavLink>
          </div>
          <div className="bg-[#E64678] h-[2px] w-full"></div>
          <div className="flex py-4 px-8 lg:px-16 justify-end ">
            <ul className="flex space-x-8 lg:space-x-16 text-[#C20475]">
              <NavLink
                to="blog"
                activeClassName="active"
                className="navbar-link"
              >
                <li className="border-[#C20475] border-b-2 border-transparent font-semibold font-urban cursor-pointer hover:text-pink-900  transition duration-1000">
                  Blog
                </li>
              </NavLink>
              <NavLink
                to="gallery"
                activeClassName="active"
                className="navbar-link"
              >
                <li className="border-[#C20475] border-b-2 border-transparent font-semibold font-urban cursor-pointer hover:text-pink-900  transition duration-1000">
                  Gallery
                </li>
              </NavLink>
              <div>
                <NavLink to="contact">
                  <button className="bg-gradient-to-b from-pink-400 to-pink-600 px-2 py-1 rounded text-white flex items-center gap-3">
                    <FaPhoneVolume /> Support
                  </button>
                </NavLink>
              </div>
            </ul>
          </div>
        </div>
        <div className="hidden md:block absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 -z-50">
          <div className="w-[640px] h-[530px] -mt-[390px] bg-pink-200 rounded-full"></div>
        </div>
      </div>
    </>
  );
};

export default Navbar;
